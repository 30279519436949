import React from 'react';

import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import DateRangeIcon from '@material-ui/icons/DateRange';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';

import { useTranslation } from 'react-multi-lang';

import { transformDateString } from '../shared/utilities';
import { dateRangeInput, dateCalendar } from '../shared/dateStyles';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(1),
  },
  input: {
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
    minWidth: '170px',
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '12px',
      borderWidth: '2px',
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
  dateInput: dateRangeInput(theme),
  dateCalendar: dateCalendar(theme),
  manualTrigger: {
    marginLeft: theme.spacing(1),
  },
  manualTriggerHint: {
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2),
    },
  },
}));

const DateRange = (props) => {
  const classes = useStyles();
  const t = useTranslation();
  const { onChangeFn, initialDateRange, initialDates, ranges, isTripsFilter, isDisabled, minDate, maxDate, isManualTrigger } = props;

  const today = transformDateString( new Date() );
  const currentMonthIndex = today.getMonth();
  const previousMonthIndex = (currentMonthIndex - 1 + 12) % 12;
  const previousPreviousMonthIndex = (currentMonthIndex - 2 + 12) % 12;

  const dateRanges = {
    none: {
      label: t('global.dateRange.none'),
      startDate: null,
      endDate: null,
    },
    today: {
      label: t('global.dateRange.today'),
      startDate: today,
      endDate: today,
    },
    next7Days: {
      label: t('global.dateRange.next7Days'),
      startDate: today,
      endDate: transformDateString( new Date( new Date().setDate(new Date().getDate() + 6) )),
    },
    next15Days: {
      label: t('global.dateRange.next15Days'),
      startDate: today,
      endDate: transformDateString( new Date( new Date().setDate(new Date().getDate() + 14) )),
    },
    last7Days: {
      label: t('global.dateRange.last7Days'),
      startDate: transformDateString( new Date( new Date().setDate(new Date().getDate() - 6) )),
      endDate: today,
    },
    last30Days: {
      label: t('global.dateRange.last30Days'),
      startDate: transformDateString( new Date( new Date().setDate(new Date().getDate() - 29) )),
      endDate: today,
    },
    last3Months: {
      label: t('global.dateRange.last3Months'),
      startDate: transformDateString( new Date( new Date().setMonth(new Date().getMonth() - 3) )),
      endDate: today,
    },
    previousPreviousMonth: {
      label: t(`global.fullMonths.${ previousPreviousMonthIndex }`),
      startDate: transformDateString( new Date(new Date().getFullYear(), new Date().getMonth() - 2, 1)),
      endDate: transformDateString( new Date(new Date().getFullYear(), new Date().getMonth() - 1, 0)),
    },
    previousMonth: {
      label: t(`global.fullMonths.${ previousMonthIndex }`),
      startDate: transformDateString( new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1)),
      endDate: transformDateString( new Date(new Date().getFullYear(), new Date().getMonth(), 0)),
    },
    currentMonth: {
      label: t(`global.fullMonths.${ currentMonthIndex }`),
      startDate: transformDateString( new Date(new Date().getFullYear(), new Date().getMonth(), 1)),
      endDate: today,
    },
    custom: {
      label: t('global.dateRange.custom'),
    },
  };

  const findMatchingRange = (range) => {
    const [startDate, endDate] = range;
    const ranges = Object.values(dateRanges);
  
    // Format the input dates for comparison (assuming transformDateString returns a string)
    const formattedStartDate = transformDateString(new Date(startDate));
    const formattedEndDate = transformDateString(new Date(endDate));
  
    // Find a matching range
    const matchingRangeIndex = ranges.findIndex(range =>
      range?.startDate?.getTime() === formattedStartDate?.getTime() &&
      range?.endDate?.getTime() === formattedEndDate?.getTime()
    );
  
    // Return the matching range or the custom range if no match found
    return matchingRangeIndex !== -1 ? Object.keys(dateRanges)[matchingRangeIndex] : 'custom';
  };
  
  const tripsFilterValue = isTripsFilter && JSON.parse(localStorage.getItem('filters'))?.date[0];
  const range = ranges?.length && ((tripsFilterValue && findMatchingRange([tripsFilterValue?.startDate, tripsFilterValue?.endDate])) || initialDateRange || ranges[0]);
  const initialData = ranges?.length ? 
                        [tripsFilterValue?.startDate || dateRanges[range].startDate, tripsFilterValue?.endDate || dateRanges[range].endDate]
                        : initialDates || [new Date(), new Date()];

  const [value, setValue] = React.useState(initialData);
  const [isCalendarOpen, setIsCalendarOpen] = React.useState(false);
  const [lastReasonTime, setLastReasonTime] = React.useState(new Date().getTime());
  const [openEl, setOpenEl] = React.useState(null);
  const [showManualTriggerHint, setShowManualTriggerHint] = React.useState(isManualTrigger);

  const [dateRange, setDateRange] = React.useState(range);

  const onChange = (data, isFromRange = false, isFromTrigger = false) => {
    const startDate = data[0] ? transformDateString(data[0]) : null;
    const endDate = data[1] ? transformDateString(data[1]) : null;
    setValue([startDate, endDate]);
    setShowManualTriggerHint(false);
    if (!isFromRange) {
      setDateRange(findMatchingRange([startDate, endDate]));
    }
    if (!isManualTrigger || isFromTrigger) {
      onChangeFn({ page: 1, startDate, endDate });
    }
  };

  const onDateRangeChange = (event) => {
    const range = event.target.value;
    const isNone = range === 'none';
    setDateRange(range);
    if (range !== 'custom') {
      onChange([isNone ? null : dateRanges[range].startDate, isNone ? null : dateRanges[range].endDate], true);
    }
  };

  React.useEffect(() => {
    if ( !isManualTrigger ) {
      onChange(initialData, true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div className={ classes.root }>
    { ranges?.length && <FormControl variant="outlined" className={ classes.input }>
      <InputLabel>{ t('global.dateRange.label') }</InputLabel>
      <Select
        value={ dateRange }
        onChange={ onDateRangeChange }
        label={ t('global.dateRange.label') }
      >
        { ranges.filter(range => !!dateRanges[range]?.label).map(range => <MenuItem
          key={ range }
          value={ range }
          disabled={ range === 'custom' }>
            { dateRanges[range].label }
        </MenuItem>) }
      </Select>
    </FormControl> }

    <DateRangePicker
      minDate={ minDate }
      maxDate={ maxDate }
      className={ classes.dateInput }
      calendarClassName={ classes.dateCalendar }
      onChange={ onChange }
      value={ value }
      format='MMM dd, y'
      dayPlaceholder='dd'
      monthPlaceholder='mm, '
      yearPlaceholder="yyyy"
      clearIcon={ null }
      calendarIcon={ <DateRangeIcon /> }
      isOpen={ isCalendarOpen }
      disabled={ isDisabled }
      locale={ localStorage.getItem('language') === 'en' ? 'en-EN' : 'es-EN' }
      onCalendarClose={ () => setIsCalendarOpen(false) } 
      onCalendarOpen={ () => setIsCalendarOpen(true) }
      onClick={(event) => {
        const el = event.target.querySelector('.react-daterange-picker__calendar-button');
        if (el && !isCalendarOpen && !openEl) {
          setOpenEl(el);
          el.click();
        } else if (openEl) {
          openEl.click();
        }
      } }
      shouldCloseCalendar={ action => {
        setLastReasonTime(new Date().getTime());
        const timePassedInSeconds = (new Date().getTime() - lastReasonTime) / 1000;
        const shouldClose = timePassedInSeconds >= 0.1 && ['escape', 'outsideAction', 'select'].includes(action.reason);
        if (shouldClose) {
          setOpenEl(null);
        }
        return shouldClose;
      } }
    />

    { isManualTrigger && (
      <Button className={ classes.manualTrigger } color="primary" onClick={ () => onChange(value, true, true) }>{ t('global.dateRange.submit') }</Button>
    ) }

    { showManualTriggerHint && (
      <Alert className={ classes.manualTriggerHint } severity="info">{ t('global.dateRange.manualTriggerText') }</Alert>
    ) }
  </div>;
}

DateRange.propTypes = {
  onChangeFn: PropTypes.func.isRequired,
  initialDateRange: PropTypes.string,
  initialDates: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string,PropTypes.instanceOf(Date)])),
  ranges: PropTypes.PropTypes.arrayOf(PropTypes.oneOf(
    ['none', 'today', 'next7Days', 'next15Days', 'last7Days', 'last30Days', 'last3Months', 'previousPreviousMonth', 'previousMonth', 'currentMonth', 'custom']
  )),
  isTripsFilter: PropTypes.bool,
  isDisabled: PropTypes.bool,
  minDate: PropTypes.oneOfType([PropTypes.string,PropTypes.instanceOf(Date)]),
  maxDate: PropTypes.oneOfType([PropTypes.string,PropTypes.instanceOf(Date)]),
  isManualTrigger: PropTypes.bool,
};

export default DateRange;
