import React from 'react';
import { withRouter } from 'react-router-dom';

import clsx from 'clsx';

import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import HomeIcon from '@material-ui/icons/Home';
import InfoIcon from '@material-ui/icons/Info';
import InputAdornment from '@material-ui/core/InputAdornment';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import VisibilityIcon from '@material-ui/icons/Visibility';

import * as Yup from 'yup';

import { Formik, Field } from 'formik';
import { TextField } from 'formik-material-ui';

import { handleEndpointErrors, FR_DAYS_ALL, SNACKBAR_TIME } from '../shared/utilities';

import Breadcrumbs from '../components/Breadcrumbs';
import LoadingCard from '../components/LoadingCard';
import CustomFormikForm from '../components//CustomFormikForm';
import CustomAccordion from '../components/CustomAccordion';
import FormikCheckbox from '../components//FormikCheckbox';
import FormikAutocomplete from '../components/FormikAutocomplete';
import FormikDaySelector from '../components//FormikDaySelector';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  content: {
    position: 'relative',
  },
  pageTitle: {
    marginBottom: theme.spacing(2.5),
  },
  clientActive: {
    margin: '0 !important',
    right: theme.spacing(.5),
    top: '0 !important',
  },
  accordionHeading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const EditClient = (props) => {
  const classes = useStyles();

  const store = useStore();

  const permissions = useStoreState(state => state.global.permissions);
  const organizationTypes = useStoreState(state => state.global.organizationTypes);
  const storeMenuTitle = useStoreActions(actions => actions.global.storeTitle);
  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const businessParks = useStoreState(state => state.businessParks.businessParks);

  const client = useStoreState(state => state.clients.client);
  const getClient = useStoreActions(actions => actions.clients.getClient);
  const storeGetClient = useStoreActions(actions => actions.clients.storeGetClient);
  const updateClient = useStoreActions(actions => actions.clients.updateClient);
  const setClientsUpdated = useStoreActions(actions => actions.clients.setClientsUpdated);

  const canEdit = permissions?.admin?.clients?.edit;

  const onGetClient = () => {
    getClient(props.match.params.id).then(() => {
      const clientsState = store.getState().clients;
      if (!clientsState.loading && !clientsState.error) {
        // console.log(clientsState);
      } else {
        handleEndpointErrors(clientsState, props, setSnackbar, t);
      }
    });
  };

  const t = useTranslation();

  const pageTitle = canEdit ? t('clients.table.actionEdit') : t('client.client');

  React.useEffect(() => {
    return () => {
      storeGetClient({});
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    onGetClient();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  storeMenuTitle(pageTitle);

  return (
    <div className={ classes.root }>
      <Breadcrumbs links={[
        { name: t('home.pageTitle'), path: '/', icon: <HomeIcon /> },
        { name: t('clients.pageTitle'), path: '/clients', icon: <RecentActorsIcon /> },
        { 
          name: canEdit ? t('clients.table.actionEdit') : client?.name || pageTitle,
          icon: canEdit ? <EditIcon /> : <VisibilityIcon />
        },
      ]} />
      <div className={ classes.content }>
        <Typography className={ classes.pageTitle } variant="h5">{ pageTitle }</Typography>
        { !client?.id ? <LoadingCard length={ 1 } height={ 196 } /> : (
          <Formik
            initialValues={{
              ...client,
              active: client.active === undefined ? true : client.active,
              name: client.name || '',
              description: client.description || '',
              category: client.category || '',
              billingRatio: client.billingRatio || '',
              park: businessParks.find(park => client.parkId === park.id) || '',
              rsvCreateHours: client.rsvCreateHours || 0,
              rsvCancelHours: client.rsvCancelHours || 0,
              rsvAllowDelete: client.rsvAllowDelete === undefined ? true : client.rsvAllowDelete,
              rsvAutoCreate: client.rsvAutoCreate || false,
              rsvAllowWithQR: client.rsvAllowWithQR === undefined ? true : client.rsvAllowWithQR,
              favoriteRoutesAllowCreate: client.favoriteRoutesAllowCreate || false,
              favoriteRoutesAllowDelete: client.favoriteRoutesAllowDelete || false,
              favoriteRoutesAnyDay: client.favoriteRoutesAnyDay || false,
              favoriteRoutesDays: client.favoriteRoutesDays?.length ? client.favoriteRoutesDays : [],
            }}
            validationSchema={ Yup.object({
              name: Yup.string()
                .max(50, t('global.errors.max', { field: t('clients.name'), length: 50 }))
                .required(t('global.errors.required', { field: t('clients.name') })),
              description: Yup.string()
                .max(100, t('global.errors.max', { field: t('clients.description'), length: 100 }))
                .required(t('global.errors.required', { field: t('clients.description') })),
              category: Yup.string()
                .required(t('global.errors.required', { field: t('clients.category') })),
              billingRatio: Yup.number()
                .typeError(t('global.errors.number', { field: t('clients.billingRatio') })),
              rsvCreateHours: Yup.number()
                .typeError(t('global.errors.number', { field: t('clients.rsvCreateHours') })),
              rsvCancelHours: Yup.number()
                .typeError(t('global.errors.number', { field: t('clients.rsvCancelHours') })),
              favoriteRoutesDays: Yup.string()
                .min(1, t('user.favoriteRoutes.add.days.error'))
                .required(t('user.favoriteRoutes.add.days.error')),
            }) }
            onSubmit={(values, { setSubmitting }) => {
              const data = {
                active: values.active,
                name: values.name,
                description: values.description,
                category: values.category,
                billingRatio: +values.billingRatio,
                parkId: values.park?.id || null,
                rsvCreateHours: values.rsvCreateHours || 0,
                rsvCancelHours: values.rsvCancelHours || 0,
                rsvAllowDelete: values.rsvAllowDelete,
                rsvAutoCreate: values.rsvAutoCreate,
                rsvAllowWithQR: values.rsvAllowWithQR,
                favoriteRoutesAllowCreate: values.favoriteRoutesAllowCreate,
                favoriteRoutesAllowDelete: values.favoriteRoutesAllowDelete,
                favoriteRoutesAnyDay: values.favoriteRoutesAnyDay,
                favoriteRoutesDays: values.favoriteRoutesDays,
              };
              updateClient({ clientId: values.id, data }).then(() => {
                const clientsState = store.getState().clients;
                if (!clientsState.loading && !clientsState.error) {
                  setClientsUpdated(true);
                  setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('global.success.saved') });
                } else {
                  handleEndpointErrors(clientsState, props, setSnackbar, t);
                }
                setSubmitting(false);
              });
            }}
          >
            {({ submitForm, isSubmitting, values }) => (
              <CustomFormikForm>
                <Field
                  component={ FormikCheckbox }
                  className={clsx( 'formik-checkbox', classes.clientActive )}
                  name="active"
                  label={ t('clients.active') }
                  disabled={ isSubmitting || !canEdit }
                />
                <Grid container spacing={ 3 }>
                  <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                    <Field
                      component={ TextField }
                      name="name"
                      type="text"
                      label={ t('clients.name') }
                      variant="outlined"
                      fullWidth
                      disabled={ isSubmitting || !canEdit }
                    />
                  </Grid>
                  <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                    <Field
                      component={ TextField }
                      name="description"
                      type="text"
                      label={ t('clients.description') }
                      variant="outlined"
                      fullWidth
                      disabled={ isSubmitting || !canEdit }
                    />
                  </Grid>
                  <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                    <Field
                      component={ FormikAutocomplete }
                      name="category"
                      disableClearable
                      options={ organizationTypes }
                      getOptionLabel={ option => option ? t(`global.organizationTypes.${ option.toLowerCase() }`) : '' }
                      textFieldProps={{ label: t('clients.category') , variant: 'outlined', readOnly: true, fullWidth: true }}
                      disabled={ isSubmitting || !canEdit }
                    />
                  </Grid>
                  <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                    <Field
                      component={ TextField }
                      name="billingRatio"
                      type="text"
                      label={ t('clients.billingRatio') }
                      variant="outlined"
                      fullWidth
                      disabled={ isSubmitting || !canEdit }
                    />
                  </Grid>
                  <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                    <Field
                      component={ TextField }
                      name="rsvCreateHours"
                      type="text"
                      label={ t('clients.rsvCreateHours') }
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip title={ t('clients.rsvCreateHoursHelper') }><InfoIcon color="disabled" /></Tooltip>
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                      disabled={ isSubmitting || !canEdit }
                    />
                  </Grid>
                  <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                    <Field
                      component={ TextField }
                      name="rsvCancelHours"
                      type="text"
                      label={ t('clients.rsvCancelHours') }
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip title={ t('clients.rsvCancelHoursHelper') }><InfoIcon color="disabled" /></Tooltip>
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                      disabled={ isSubmitting || !canEdit }
                    />
                  </Grid>
                  <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                    <Field
                      component={ FormikAutocomplete }
                      name="park"
                      options={ businessParks }
                      getOptionLabel={ option => option ? option.name : '' }
                      textFieldProps={{ label: t('clients.businessPark') , variant: 'outlined', readOnly: true, fullWidth: true }}
                      disabled={ isSubmitting || !canEdit }
                    />
                  </Grid>
                  <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                    <Field
                      component={ FormikCheckbox }
                      name="rsvAllowDelete"
                      label={ t('clients.rsvAllowDelete') }
                      disabled={ isSubmitting || !canEdit }
                    />
                  </Grid>
                  <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                    <Field
                      component={ FormikCheckbox }
                      name="rsvAutoCreate"
                      label={ t('clients.rsvAutoCreate') }
                      disabled={ isSubmitting || !canEdit }
                    />
                  </Grid>
                  <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                    <Field
                      component={ FormikCheckbox }
                      name="rsvAllowWithQR"
                      label={ t('clients.rsvAllowWithQR') }
                      disabled={ isSubmitting }
                    />
                  </Grid>
                  <Grid item xs={ 12 } className={ classes.favoriteRoutes }>
                    <CustomAccordion>
                      <AccordionSummary expandIcon={ <ExpandMoreIcon /> }>
                        <Typography className={ classes.accordionHeading }>{ t('clients.favoriteRoutes.title') }</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={ 2 }>
                          <Grid item xs={ 12 } sm={ 6 }>
                            <Field
                              component={ FormikCheckbox }
                              name="favoriteRoutesAllowCreate"
                              label={ t('clients.favoriteRoutes.favoriteRoutesAllowCreate') }
                              disabled={ isSubmitting }
                            />
                          </Grid>
                          <Grid item xs={ 12 } sm={ 6 }>
                            <Field
                              component={ FormikCheckbox }
                              name="favoriteRoutesAllowDelete"
                              label={ t('clients.favoriteRoutes.favoriteRoutesAllowDelete') }
                              disabled={ isSubmitting }
                            />
                          </Grid>
                          <Grid item xs={ 12 }>
                            <Field
                              component={ FormikCheckbox }
                              name="favoriteRoutesAnyDay"
                              label={ t('clients.favoriteRoutes.favoriteRoutesAnyDay') }
                              disabled={ isSubmitting }
                            />
                          </Grid>
                          <Grid item xs={ 12 }>
                            <Field
                              component={ FormikDaySelector }
                              name="favoriteRoutesDays"
                              label={ t('clients.favoriteRoutes.favoriteRoutesDays') }
                              disabled={ isSubmitting }
                              allowedDays={ FR_DAYS_ALL }
                              allowAnyDay={ values?.favoriteRoutesAnyDay }
                            />
                          </Grid>
                          <Grid item xs={ 12 }>
                            <Alert severity="info">{ t('clients.favoriteRoutes.alert') }</Alert>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </CustomAccordion>
                  </Grid>
                  { canEdit && <Grid item xs={ 12 }>
                    <Button disabled={ isSubmitting } onClick={ submitForm } color="primary">{ t('global.modal.btnUpdate') }</Button>
                  </Grid> }
                </Grid>
              </CustomFormikForm>
            )}
          </Formik>
        ) }
      </div>
    </div>
  );
}

export default withRouter(EditClient);
