import React from 'react';

import PropTypes from 'prop-types';

import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DirectionsIcon from '@material-ui/icons/Directions';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import Grid from '@material-ui/core/Grid';
import PersonIcon from '@material-ui/icons/Person';
import ScheduleIcon from '@material-ui/icons/Schedule';

import * as Yup from 'yup';

import { Formik, Field } from 'formik';

import { handleEndpointErrors, transformDate, formatTime, sortByTime, sortByProperty, SNACKBAR_TIME } from '../../../../../shared/utilities';
import { useDebounce } from '../../../../../shared/hooks/useDebounce';

import CustomFormikForm from '../../../../CustomFormikForm';
import FormikRadioGroup from '../../../../FormikRadioGroup';
import FormikAutocomplete from '../../../../FormikAutocomplete';
import FormikDays from '../../../../FormikDays';
import LoadingCard from '../../../../LoadingCard';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  form: {
    marginTop: theme.spacing(-4),
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2, 0, 0),
    },
    '& .MuiDialogContent-root > *': {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
      '&:first-of-type': {
        marginTop: 0,
      },
    },
    '& input:-webkit-autofill': {
      '-webkit-transition': 'color 9999s ease-out, background-color 9999s ease-out',
      '-webkit-transition-delay': '9999s',
    },
  },
}));

const AddTrip = (props) => {
  const { onAddTripModalClose, onGetTrips } = props;
  const classes = useStyles();

  const store = useStore();

  const clientSelected = useStoreState(state => state.global.clientSelected);
  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const addTrip = useStoreActions(actions => actions.trips.addTrip);
  const getHeatMapTrips = useStoreActions(actions => actions.trips.getHeatMapTrips);

  const routes = useStoreState(state => state.routes.routes);
  const routesLoading = useStoreState(state => state.routes.loading);
  const getRoutes = useStoreActions(actions => actions.routes.getRoutes);

  const routeSchedules = useStoreState(state => state.routes.routeSchedules);
  const routeSchedulesLoading = useStoreState(state => state.routes.routeSchedulesLoading);
  const getRouteSchedules = useStoreActions(actions => actions.routes.getRouteSchedules);

  const routeVehicles = useStoreState(state => state.routes.routeVehicles);
  const getRouteVehicles = useStoreActions(actions => actions.routes.getRouteVehicles);

  const [routeName, setRouteName] = React.useState('');
  const debouncedRouteName = useDebounce(routeName, 500);

  const onGetRoutes = (payload) => {
    getRoutes(payload).then(() => {
      const routesState = store.getState().routes;
      if (!routesState.loading && !routesState.error) {
        // console.log(routesState);
      } else {
        handleEndpointErrors(routesState, props, setSnackbar, t);
      }
    });
  }

  const onGetRouteSchedules = (routeId) => {
    getRouteSchedules(routeId).then(() => {
      const routesState = store.getState().routes;
      if (!routesState.routeSchedulesLoading && !routesState.routeSchedulesError) {
        // console.log(routesState);
      } else {
        handleEndpointErrors({ ...routesState, loading: routesState.routeSchedulesLoading, error: routesState.routeSchedulesError }, props, setSnackbar, t);
      }
    });
  }

  const onGetRouteVehicles = (routeId) => {
    getRouteVehicles(routeId).then(() => {
      const routesState = store.getState().routes;
      if (!routesState.routeVehiclesLoading && !routesState.routeVehiclesError) {
        // console.log(routesState);
      } else {
        handleEndpointErrors({ ...routesState, loading: routesState.routeVehiclesLoading, error: routesState.routeVehiclesError }, props, setSnackbar, t);
      }
    });
  }

  React.useEffect(() => {
    onGetRoutes({ direction: formRef.current.values.direction, textSearch: debouncedRouteName, active: true });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedRouteName]);

  const formRef = React.useRef();

  const getDays = (routeSchedule) => routeSchedule.days.map(day => ({
    day: transformDate(day).split('/').join('-'),
    dateFormat: new Date(transformDate(day)),
    checked: false
  }));;

  // eslint-disable-next-line array-callback-return
  const updateFields = (fields, setFieldValue, setFieldTouched, values) => fields.map(field => {
    field === 'route' && onGetRoutes({ direction: values.direction === 'in' ? 'out' : 'in', active: true });
    field === 'days' ?
      setFieldValue(`${ field }`, values.time ? getDays(values.time) : [], false) :
      setFieldValue(`${ field }`, '', false);

    setFieldTouched(`${ field }`, false);
  });

  const t = useTranslation();

  const directionOptions = [
    { label: t('trips.addTripModal.addTrip.direction.in'), value: 'in' },
    { label: t('trips.addTripModal.addTrip.direction.out'), value: 'out' }
  ];

  const extraOptions = [
    { label: t('trips.addTripModal.addTrip.extra.true'), value: 'true' },
    { label: t('trips.addTripModal.addTrip.extra.false'), value: 'false' }
  ];

  return (
    <React.Fragment>
      <Formik
        innerRef={ formRef }
        initialValues={{ direction: 'in', extra: 'false', route: '', time: '', vehicle: '', driver: '', days: [] }}
        validationSchema={() => Yup.lazy((values) => {
          return Yup.object({
            route: Yup.string()
              .required(t('global.errors.required', { field: t('trips.addTripModal.addTrip.route') })),
            time: Yup.string()
              .required(t('global.errors.required', { field: t(`trips.addTripModal.addTrip.time.${ values.direction }`) })),
            vehicle: Yup.string()
              .required(t('global.errors.required', { field: t('trips.addTripModal.addTrip.vehicle') })),
            driver: Yup.string()
              .required(t('global.errors.required', { field: t('trips.addTripModal.addTrip.driver') })),
            days: Yup.array()
              .test('days', t('global.errors.required', { field: t('trips.addTripModal.addTrip.days.label') }), days => days.filter(day => day.checked).length),
          })
        })}
        onSubmit={(values, { setSubmitting }) => {
          const data = {
            routeId: values.route.id,
            routeScheduleId: values.time.routeScheduleId,
            vehicleId: values.vehicle.vehicleId,
            driverId: values.driver.id,
            dates: values.days.filter(day => day.checked).map(day => day.day),
            extra: values.extra === 'true',
            ...(clientSelected !== 'all' && { clientId: clientSelected })
          };
          addTrip(data).then(() => {
            const tripsState = store.getState().trips;
            if (!tripsState.error) {
              if ( localStorage.getItem('tripsSidebarTab') === '1' ) {
                getHeatMapTrips();
              } else {
                onGetTrips();
              }
              onAddTripModalClose();
              setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('trips.addTripModal.addTrip.successMessage') });
            } else {
              handleEndpointErrors(tripsState, props, setSnackbar, t, t('trips.errorEntity'));
            }
            setSubmitting(false);
          });
        }}
      >
        {({ setFieldValue, setFieldTouched, values, submitForm, isSubmitting }) => (
          <CustomFormikForm className={ classes.form }>
            <DialogContent>
              <Grid container spacing={ 3 }>
                <Grid item xs={ 12 } md={ 6 }>
                  { routesLoading ? <LoadingCard length={ 1 } height={ 73 } /> : (
                   <Field
                    name="direction"
                    component={ FormikRadioGroup }
                    label={ t('trips.addTripModal.addTrip.direction.label') }
                    options={ directionOptions }
                    disabled={ isSubmitting }
                    onChange={() => {
                      updateFields(['route', 'driver', 'vehicle', 'time'], setFieldValue, setFieldTouched, formRef.current.values);
                    }}
                  />
                  ) }
                </Grid>
                <Grid item xs={ 12 } md={ 6 }>
                  { routesLoading ? <LoadingCard length={ 1 } height={ 73 } /> : (
                   <Field
                    name="extra"
                    component={ FormikRadioGroup }
                    label={ t('trips.addTripModal.addTrip.extra.label') }
                    options={ extraOptions }
                    disabled={ isSubmitting }
                  />
                  ) }
                </Grid>
                <Grid item xs={ 12 } md={ 6 }>
                  { routesLoading ? <LoadingCard length={ 1 } height={ 56 } /> : (
                    <Field
                      component={ FormikAutocomplete }
                      name='route'
                      disableClearable
                      options={ sortByProperty(routes.filter(route => route.clients?.length) || [], 'name') }
                      getOptionLabel={ option => option ? option.name : '' }
                      textFieldProps={{
                        label: t('trips.addTripModal.addTrip.route'),
                        variant: 'outlined',
                        fullWidth: true,
                        icon: <DirectionsIcon />,
                        onChange: event => {
                          setRouteName( event.target.value );
                        }
                      }}
                      onFocus={() => {
                        setRouteName('');
                      }}
                      onChange={() => {
                        setTimeout(() => {
                          const route = formRef.current.values.route;
                          if (route) {
                            updateFields(['time', 'vehicle', 'driver', 'days'], setFieldValue, setFieldTouched, formRef.current.values);
                            onGetRouteSchedules(route.id);
                            onGetRouteVehicles(route.id);
                          }
                        });
                      }}
                    />
                  ) }
                </Grid>
                <Grid item xs={ 12 } md={ 6 }>
                  { routesLoading ? <LoadingCard length={ 1 } height={ 56 } /> : (
                    <Field
                      component={ FormikAutocomplete }
                      name='time'
                      options={ routeSchedulesLoading ? [] : values.direction === 'in' ? sortByTime(routeSchedules, 'arrivalTime') : sortByTime(routeSchedules, 'departureTime') }
                      getOptionLabel={ option => option ? `${ values.direction === 'in' ? formatTime(option.arrivalTime) : formatTime(option.departureTime) }` : '' }
                      textFieldProps={{ label: t(`trips.addTripModal.addTrip.time.${ values.direction }`), variant: 'outlined', readOnly: true, fullWidth: true, icon: <ScheduleIcon /> }}
                      disabled={ !values.route }
                      noOptionsText={ routeSchedulesLoading ? t('global.noOptionsLoading') : undefined }
                      onChange={() => {
                        setTimeout(() => {
                          updateFields(['vehicle', 'driver', 'days'], setFieldValue, setFieldTouched, formRef.current.values);
                        });
                      }}
                    />
                  ) }
                </Grid>
                <Grid item xs={ 12 } md={ 6 }>
                  { routesLoading ? <LoadingCard length={ 1 } height={ 56 } /> : (
                    <Field
                      component={ FormikAutocomplete }
                      name='vehicle'
                      options={ routeVehicles }
                      getOptionLabel={ option => option ? `${ option.licensePlate } - ${ option.make } ${ option.model }, ${ option.year }` : '' }
                      textFieldProps={{ label: t('trips.addTripModal.addTrip.vehicle'), variant: 'outlined', readOnly: true, fullWidth: true, icon: <DriveEtaIcon /> }}
                      disabled={ !values.time }
                      onChange={ () => {
                        setTimeout(() => {
                          const drivers = formRef.current.values.vehicle.drivers;
                          setFieldValue('driver', drivers[0], false);
                        });
                      } }
                    />
                  ) }
                </Grid>
                <Grid item xs={ 12 } md={ 6 }>
                  <Field
                    component={ FormikAutocomplete }
                    name='driver'
                    options={ sortByProperty(values.vehicle.drivers || [], 'name') }
                    getOptionLabel={ option => option ? option.name : '' }
                    textFieldProps={{ label: t('trips.addTripModal.addTrip.driver'), variant: 'outlined', readOnly: true, fullWidth: true, icon: <PersonIcon /> }}
                    disabled={ !values.vehicle }
                  />
                </Grid>
                { values.time ? <Grid item xs={ 12 }>
                  <Field
                    name="days"
                    component={ FormikDays }
                    label={ t('trips.addTripModal.addTrip.days.label') }
                    options={ values.days }
                    disabled={ isSubmitting }
                  />
                </Grid> : null }
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={ onAddTripModalClose } color="primary">{ t('global.modal.btnClose') }</Button>
              <Button disabled={ isSubmitting } onClick={ submitForm } color="primary">{ t('global.modal.btnAdd') }</Button>
            </DialogActions>
          </CustomFormikForm>
        )}
      </Formik>
    </React.Fragment>
  );
}

AddTrip.propTypes = {
  onAddTripModalClose: PropTypes.func.isRequired,
  onGetTrips: PropTypes.func.isRequired,
};

export default AddTrip;
