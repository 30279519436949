import React from 'react';

import PropTypes from 'prop-types';

import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import DialogActions from '@material-ui/core/DialogActions';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';

import DateRange from '../../../../DateRange';
import { DEFAULT_TRIP_FILTERS } from '../../../../../store/models/trips';

import { transformDateString, getCurrentWeekMonday, getCurrentWeekSunday, getNextWeekMonday, getNextWeekSunday, handleEndpointErrors } from '../../../../../shared/utilities';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTypography-root + div:not(.MuiFormControl-root):not(.MuiFormGroup-root)': {
      marginBottom: theme.spacing(1),
      marginTop: 0,
    },
    '& ul': {
      listStyle: 'none',
      margin: `${theme.spacing(1)}px 0 ${theme.spacing(2)}px`,
      padding: 0,
    },
    '& li': {
      ...theme.typography.caption,
      color: theme.palette.error.main
    },
  },
  generateTripsBy: {
    margin: theme.spacing(2, 0, -2),
    '& .Mui-checked': {
      color: theme.palette.secondary.dark,
    },
    '& legend': {
      ...theme.typography.overline,
      color: theme.palette.text.primary,
      marginBottom: theme.spacing(-1),
    },
  },
  dates: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      gap: `${ theme.spacing(3) }px`,
    },
  },
  select: {
    margin: theme.spacing(3, 0, 2),
    display: 'block',
    '& .MuiOutlinedInput-root': {
      borderRadius: theme.spacing(1.5),
      minWidth: '125px',
    },
    '& .MuiOutlinedInput-input': {
      padding: theme.spacing('18.5px', 5, '18.5px', '14px'),
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: '2px',
    },
  },
  options: {
    '& > .MuiTypography-root': {
      display: 'block',
      marginBottom: theme.spacing(-1),
    },
    '& .MuiFormGroup-root': {
      marginBottom: theme.spacing(-1),
    },
    '& > div:nth-of-type(2), & > div:nth-of-type(3)': {
      paddingLeft: theme.spacing(1.5),
    },
  },
  alerts: {
    marginTop: theme.spacing(2),
    '& .MuiAlert-root': {
      marginBottom: theme.spacing(1),
    },
  }
}));

const GenerateTrips = (props) => {
  const { onAddTripModalClose, onGetTrips } = props;
  const classes = useStyles();

  const store = useStore();

  const clients = useStoreState(state => state.global.clients);
  const clientSelected = useStoreState(state => state.global.clientSelected);
  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const businessParks = useStoreState(state => state.businessParks.businessParks);

  const setFilters = useStoreActions(actions => actions.trips.setFilters);
  const setTripSelected = useStoreActions(actions => actions.trips.setCurrentTrip);
  const getHeatMapTrips = useStoreActions(actions => actions.trips.getHeatMapTrips);
  const generateTrips = useStoreActions(actions => actions.trips.generateTrips);
  const generateReservations = useStoreActions(actions => actions.trips.generateReservations);
  const assignReservations = useStoreActions(actions => actions.trips.assignReservations);
  
  const [generateTripsBy, setGenerateTripsBy] = React.useState('client');
  const [tripsClientSelected, setTripsClientSelected] = React.useState(clientSelected);
  const [tripsParkSelected, setTripsParkSelected] = React.useState('');
  const [dates, setDates] = React.useState({});
  const [isSameLength, setIsSameLength] = React.useState(false);
  const [isWeekOrLessLength, setIsWeekOrLessLength] = React.useState(false);
  const [isDateOk, setIsDateOk] = React.useState(false);
  const [filterDatesChecked, setFilterDatesChecked] = React.useState(false);
  const [generateTripsChecked, setGenerateTripsChecked] = React.useState(false);
  const [generateReservationsChecked, setGenerateReservationsChecked] = React.useState(false);
  const [assignReservationsChecked, setAssignReservationsChecked] = React.useState(false);
  const [includeCancelledTripsChecked, setIncludeCancelledTripsChecked] = React.useState(false);
  const [actionsStatus, setActionsStatus] = React.useState({ // info, error, success
    generateTrips: null,
    generateReservations: null,
    assignReservations: null,
  });
  const [disabledActions, setDisabledActions] = React.useState([]);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const DAY_MILLISECONDS = 86400000;
  const sixWeeks = transformDateString( new Date( getNextWeekMonday().setDate(getNextWeekMonday().getDate() + 42) ));
  const isClientSelected = tripsClientSelected !== 'all';
  const isParkSelected = !!tripsParkSelected;
  const hasTakenAction = Object.values(actionsStatus).some(action => !!action);
  const actionsText = 'trips.addTripModal.generateTrips.actions';
  const hasData = (generateTripsBy === 'businessPark' && isParkSelected) || (generateTripsBy === 'client' && isClientSelected);
  const isOneChecked = [generateTripsChecked ,generateReservationsChecked ,assignReservationsChecked].some(checked => checked);

  const onClientSelectedChange = (e) => {
    setTripsClientSelected(e.target.value);
  };

  const onParkSelectedChange = (e) => {
    setTripsParkSelected(e.target.value);
  };

  const handleGenerateTripsByChange = (event) => {
    setGenerateTripsBy(event.target.value);
  };

  // const sumDateDays = (date, days) => new Date( date.setDate(date.getDate() + days) );
  // const restDateDays = (date, days) => new Date( date.setDate(date.getDate() - days) );
  const getDatesDiffDays = (startDate, endDate) => startDate && endDate ? Math.round((endDate-startDate) / (1000*60*60*24)) : '';
  const compareDates = (firstDate, secondDate) =>  firstDate?.getTime()-secondDate?.getTime() >= DAY_MILLISECONDS;

  const getSourceDates = ({startDate, endDate}) => {
    setDates(state => {
      setIsSameLength( getDatesDiffDays(state.target?.startDate, state.target?.endDate) === getDatesDiffDays(startDate, endDate) );
      setIsWeekOrLessLength(getDatesDiffDays(startDate, endDate) <= 6);
      setIsDateOk( compareDates(state.target?.startDate, endDate) );
      return {
        ...state,
        source: { startDate, endDate }
      };
    });
  };

  const getTargetDates = ({startDate, endDate}) => {
    setDates(state => {
      setIsSameLength( getDatesDiffDays(state.source?.startDate, state.source?.endDate) === getDatesDiffDays(startDate, endDate) );
      setIsWeekOrLessLength(getDatesDiffDays(startDate, endDate) <= 6);
      setIsDateOk( compareDates(startDate, state.source?.endDate) );
      return {
        ...state,
        target: { startDate, endDate }
      };
    });
  };

  const onFilterDatesChange = event => {
    setFilterDatesChecked(event.target.checked);
  };

  const onGenerateTripsChange = event => {
    setGenerateTripsChecked(event.target.checked);

    if (!event.target.checked) {
      setIncludeCancelledTripsChecked(false);
      setFilterDatesChecked(false);
    }
  };

  const onGenerateReservationsChange = event => {
    setGenerateReservationsChecked(event.target.checked);
  };

  const onAssignReservationsChange = event => {
    setAssignReservationsChecked(event.target.checked);
  };

  const onIncludeCancelledTripsChange = event => {
    setIncludeCancelledTripsChecked(event.target.checked);
  };

  const getTrips = () => {
    if ( localStorage.getItem('tripsSidebarTab') === '1' ) {
      getHeatMapTrips();
    } else {
      onGetTrips();
    }
  };

  const updateDateFilter = () => {
    if ( filterDatesChecked ) {
      setFilters({
        ...DEFAULT_TRIP_FILTERS,
        date: [{ startDate: dates.target.startDate, endDate: dates.target.endDate }]
      });
    }
  };

  const handleCountMessage = (count, action) => {
    switch (count) {
      case 0:
        setActionsStatus(prevStatus => ({ ...prevStatus, [action]: { severity: 'info', message: t(`${ actionsText }.${action}.success.zero`) } }));
        setDisabledActions(actions => [...new Set([...actions, action])]);
        break;
      case 1:
        setActionsStatus(prevStatus => ({ ...prevStatus, [action]: { severity: 'success', message: t(`${ actionsText }.${action}.success.single`, {count: count}) } }));
        break;
      default:
        setActionsStatus(prevStatus => ({ ...prevStatus, [action]: { severity: 'success', message: t(`${ actionsText }.${action}.success.multiple`, {count: count}) } }));
    }
  };

  const onGenerateTrips = () => {
    setIsSubmitting(true);

    const data = {
      sourceDateFrom: dates.source.startDate.toISOString().split('T')[0],
      sourceDateTo: dates.source.endDate.toISOString().split('T')[0],
      targetDateFrom: dates.target.startDate.toISOString().split('T')[0],
      targetDateTo: dates.target.endDate.toISOString().split('T')[0],
      ...(includeCancelledTripsChecked && { includeCancelledTrips: true }),
      ...(generateTripsBy === 'client' && { clientId: tripsClientSelected }),
      ...(generateTripsBy === 'businessPark' && { parkId: tripsParkSelected }),
    };

    setActionsStatus(prevStatus => ({ ...prevStatus, generateTrips: { severity: 'info', message: t(`${ actionsText }.generateTrips.info`) } }));

    generateTrips(data).then((response) => {
      setIsSubmitting(false);

      const tripsState = store.getState().trips;
      if (!tripsState.error) {
        updateDateFilter();
        setTripSelected({});
        getTrips();
        handleCountMessage(response.data.count, 'generateTrips');
        setDisabledActions(actions => [...new Set([...actions, 'generateTrips'])]);
        if (generateReservationsChecked && !disabledActions.includes('generateReservations')) {
          onGenerateReservations();
        } else if (assignReservationsChecked && !disabledActions.includes('assignReservations')) {
          onAssignReservations();
        }
        if (response.data.count) {
          setTimeout(() => setDisabledActions(actions => [...actions].filter(a => !['generateReservations', 'assignReservations'].includes(a))), 500);
        }
      } else {
        setActionsStatus(prevStatus => ({ ...prevStatus, generateTrips: { severity: 'error', message: t(`${ actionsText }.generateTrips.error`) } }));
        handleEndpointErrors(tripsState, props, setSnackbar, t, 'parks');
      }
    });
  };

  const onGenerateReservations = () => {
    setIsSubmitting(true);

    const data = {
      targetDateFrom: dates.target.startDate.toISOString().split('T')[0],
      targetDateTo: dates.target.endDate.toISOString().split('T')[0],
      ...(generateTripsBy === 'client' && { clientId: tripsClientSelected }),
      ...(generateTripsBy === 'businessPark' && { parkId: tripsParkSelected }),
    };

    setActionsStatus(prevStatus => ({ ...prevStatus, generateReservations: { severity: 'info', message: t(`${ actionsText }.generateReservations.info`) } }));

    generateReservations(data).then((response) => {
      setIsSubmitting(false);

      const tripsState = store.getState().trips;
      if (!tripsState.error) {
        updateDateFilter();
        getTrips();
        handleCountMessage(response.data.count, 'generateReservations');
        setDisabledActions(actions => [...new Set([...actions, 'generateReservations'])]);
        if (assignReservationsChecked && !disabledActions.includes('assignReservations')) {
          onAssignReservations();
        }
        if (response.data.count) {
          setTimeout(() => setDisabledActions(actions => [...actions].filter(a => !['assignReservations'].includes(a))), 500);
        }
      } else {
        setActionsStatus(prevStatus => ({ ...prevStatus, generateReservations: { severity: 'error', message: t(`${ actionsText }.generateReservations.error`) } }));
        handleEndpointErrors(tripsState, props, setSnackbar, t, 'parks');
      }
    });
  };

  const onAssignReservations = () => {
    setIsSubmitting(true);

    const data = {
      targetDateFrom: dates.target.startDate.toISOString().split('T')[0],
      targetDateTo: dates.target.endDate.toISOString().split('T')[0],
      ...(generateTripsBy === 'client' && { clientId: tripsClientSelected }),
      ...(generateTripsBy === 'businessPark' && { parkId: tripsParkSelected }),
    };

    setActionsStatus(prevStatus => ({ ...prevStatus, assignReservations: { severity: 'info', message: t(`${ actionsText }.assignReservations.info`) } }));

    assignReservations(data).then((response) => {
      setIsSubmitting(false);

      const tripsState = store.getState().trips;
      if (!tripsState.error) {
        updateDateFilter();
        getTrips();
        handleCountMessage(response.data.count, 'assignReservations');
        setDisabledActions(actions => [...new Set([...actions, 'assignReservations'])]);
      } else {
        setActionsStatus(prevStatus => ({ ...prevStatus, assignReservations: { severity: 'error', message: t(`${ actionsText }.assignReservations.error`) } }));
        handleEndpointErrors(tripsState, props, setSnackbar, t, 'parks');
      }
    });
  };
  
  React.useEffect(() => {
    setTripsClientSelected(clientSelected);
    setTripsParkSelected('');
    setGenerateTripsChecked(true);
    setGenerateReservationsChecked(generateTripsBy === 'businessPark');
    setAssignReservationsChecked(generateTripsBy === 'businessPark');
    setIncludeCancelledTripsChecked(generateTripsBy === 'businessPark');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generateTripsBy]);

  const t = useTranslation();

  return (
    <div className={ classes.root }>
      <Typography variant="body2" gutterBottom>{ t('trips.addTripModal.generateTrips.description') }</Typography>
      { !!businessParks.length && <FormControl component="fieldset" className={ classes.generateTripsBy }>
        <FormLabel component="legend" >{ t('trips.addTripModal.generateTrips.generateTripsBy') }</FormLabel>
        <RadioGroup row value={generateTripsBy} onChange={handleGenerateTripsByChange}>
          <FormControlLabel value="client" control={<Radio disabled={ hasTakenAction } />} label={ t('trips.addTripModal.generateTrips.client') } />
          <FormControlLabel value="businessPark" control={<Radio disabled={ hasTakenAction } />} label={ t('trips.addTripModal.generateTrips.businessPark') } />
        </RadioGroup>
      </FormControl> }
      { generateTripsBy === 'client' && <FormControl variant="outlined" className={ classes.select }>
        <InputLabel>{ t('trips.addTripModal.generateTrips.client') }</InputLabel>
        <Select
          value={ isClientSelected ? tripsClientSelected : '' }
          onChange={ onClientSelectedChange }
          label={ t('trips.addTripModal.generateTrips.client') }
          disabled={ hasTakenAction }
          >
            { clients.map(client => <MenuItem key={ client.id } value={ client.id }>{ client.name }</MenuItem>) }
        </Select>
      </FormControl> }
      { !!businessParks.length && generateTripsBy === 'businessPark' && <FormControl variant="outlined" className={ classes.select }>
        <InputLabel>{ t('trips.addTripModal.generateTrips.businessPark') }</InputLabel>
        <Select
          value={ tripsParkSelected }
          onChange={ onParkSelectedChange }
          label={ t('trips.addTripModal.generateTrips.businessPark') }
          disabled={ hasTakenAction }
          >
            { businessParks.map(park => <MenuItem key={ park.id } value={ park.id }>{ park.name }</MenuItem>) }
        </Select>
      </FormControl> }
      <div className={ classes.dates }>
        <div>
          <Typography variant="overline">{ t('trips.addTripModal.generateTrips.source') }</Typography>
          <DateRange
            onChangeFn={ getSourceDates }
            initialDates={ [getCurrentWeekMonday(), getCurrentWeekSunday()] }
            maxDate={ sixWeeks }
            isDisabled={ hasTakenAction }
          />
        </div>
        <div>
          <Typography variant="overline">{ t('trips.addTripModal.generateTrips.target') }</Typography>
          <DateRange
            onChangeFn={ getTargetDates }
            initialDates={ [getNextWeekMonday(), getNextWeekSunday()] }
            maxDate={ sixWeeks }
            isDisabled={ hasTakenAction }
          />
        </div>
      </div>
      { hasData && <React.Fragment>
        <div className={ classes.options }>
          <Typography variant="overline">{ t('trips.addTripModal.generateTrips.actions.options') }</Typography>
          <FormGroup row>
            <FormControlLabel
              control={ <Checkbox
                disabled={ disabledActions.includes('generateTrips') }
                checked={ generateTripsChecked }
                onChange={ onGenerateTripsChange }
                name="generateTrips" />
              }
              label={ t(`${ actionsText }.generateTrips.name`) }
            />
          </FormGroup>
          <FormGroup row>
            <FormControlLabel
              control={ <Checkbox
                disabled={ !generateTripsChecked || disabledActions.includes('generateTrips') }
                checked={ includeCancelledTripsChecked }
                onChange={ onIncludeCancelledTripsChange }
                name="includeCancelledTrips" />
              }
              label={ t('trips.addTripModal.generateTrips.includeCancelledTrips') }
            />
          </FormGroup>
          <FormGroup row>
            <FormControlLabel
              control={ <Checkbox
                disabled={ !generateTripsChecked || disabledActions.includes('generateTrips') }
                checked={ filterDatesChecked }
                onChange={ onFilterDatesChange }
                name="filterDates" />
              }
              label={ t('trips.addTripModal.generateTrips.filterDates') }
            />
          </FormGroup>
          <FormGroup row>
            <FormControlLabel
              control={ <Checkbox
                disabled={ disabledActions.includes('generateReservations') }
                checked={ generateReservationsChecked }
                onChange={ onGenerateReservationsChange }
                name="generateReservations" />
              }
              label={ t(`${ actionsText }.generateReservations.name`) }
            />
          </FormGroup>
          <FormGroup row>
            <FormControlLabel
              control={ <Checkbox
                disabled={ disabledActions.includes('assignReservations') }
                checked={ assignReservationsChecked }
                onChange={ onAssignReservationsChange }
                name="assignReservations" />
              }
              label={ t(`${ actionsText }.assignReservations.name`) }
            />
          </FormGroup>
        </div>
      </React.Fragment> }
      <div className={ classes.alerts }>
        { Object.keys(actionsStatus).filter(action => actionsStatus[action]).map(action => (
          <Alert key={action} variant="outlined" severity={actionsStatus[action].severity}>{ actionsStatus[action].message }</Alert>
        )) }
      </div>
      <ul>
        { generateTripsBy === 'client' && !isClientSelected && <li>- { t('trips.addTripModal.generateTrips.errors.selectClient') }</li> }
        { generateTripsBy === 'businessPark' && !isParkSelected && <li>- { t('trips.addTripModal.generateTrips.errors.selectBusinessPark') }</li> }
        { !isSameLength && <li>- { t('trips.addTripModal.generateTrips.errors.sameLength') }</li> }
        { !isDateOk && <li>- { t('trips.addTripModal.generateTrips.errors.dateOk') }</li> }
        { !isWeekOrLessLength && <li>- { t('trips.addTripModal.generateTrips.errors.weekOrLessLength') }</li> }
      </ul>
      <DialogActions>
        <Button onClick={ onAddTripModalClose } color="primary">{ t('global.modal.btnClose') }</Button>
        <Button
          color="primary"
          disabled={ isSubmitting || disabledActions?.length === 3 || !isOneChecked || !hasData || !isSameLength || !isDateOk || !isWeekOrLessLength }
          onClick={ () => generateTripsChecked && !disabledActions.includes('generateTrips') ? onGenerateTrips()
            : generateReservationsChecked && !disabledActions.includes('generateReservations') ? onGenerateReservations()
            : onAssignReservations() }
        >
          { t('trips.addTripModal.generateTrips.generateBtn') }
        </Button>
      </DialogActions>
    </div>
  );
}

GenerateTrips.propTypes = {
  onAddTripModalClose: PropTypes.func.isRequired,
  onGetTrips: PropTypes.func.isRequired,
};

export default GenerateTrips;