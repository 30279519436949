export const dateInput = (theme) => ({
  cursor: 'pointer',
  '& .react-date-picker__wrapper': {
    border: `2px solid ${ theme.palette.type === 'dark' ? 'rgba(255, 255, 255, 0.23)' : 'rgba(0, 0, 0, 0.23)' }`,
    borderRadius: '12px',
    padding: theme.spacing(1.25, 1),
  },
  '& .react-date-picker__wrapper:hover': {
    borderColor: theme.palette.text.primary,
  },
  '& .react-date-picker__inputGroup, & .react-date-picker__calendar-button': {
    pointerEvents: 'none',
  },
  '& select.react-date-picker__inputGroup__input, & input.react-date-picker__inputGroup__input, & input.react-date-picker__inputGroup__input::placeholder': {
    color: theme.palette.text.primary,
  },
  '& select.react-date-picker__inputGroup__input': {
    '-webkit-appearance': 'none',
    '-moz-appearance': 'none',
    marginRight: '-2px',
    textIndent: '1px',
    textOverflow: '',
  },
  '& select.react-date-picker__inputGroup__input:has(option:checked:not([value=""]))': {
    textTransform: 'capitalize',
  },
  '& .MuiSvgIcon-root': {
    color: theme.palette.text.primary,
  },
  '& .react-date-picker__calendar': {
    zIndex: 12,
  },
  '&.react-date-picker--disabled': {
    backgroundColor: 'transparent',
    color: theme.palette.text.disabled,
    cursor: 'default',
  },
  '&.react-date-picker--disabled .react-date-picker__wrapper': {
    borderColor: theme.palette.text.disabled,
  },
  '&.react-date-picker--disabled select.react-date-picker__inputGroup__input, &.react-date-picker--disabled input.react-date-picker__inputGroup__input, &.react-date-picker--disabled .MuiSvgIcon-root': {
    color: theme.palette.text.disabled,
    opacity: 1,
  },
});

export const dateRangeInput = (theme) => ({
  cursor: 'pointer',
  '& .react-daterange-picker__wrapper': {
    border: `2px solid ${ theme.palette.type === 'dark' ? 'rgba(255, 255, 255, 0.23)' : 'rgba(0, 0, 0, 0.23)' }`,
    borderRadius: '12px',
    padding: theme.spacing(1.25, 1),
  },
  '& .react-daterange-picker__wrapper:hover': {
    borderColor: theme.palette.text.primary,
  },
  '& .react-daterange-picker__inputGroup, & .react-daterange-picker__calendar-button': {
    pointerEvents: 'none',
  },
  '& select.react-daterange-picker__inputGroup__input, & input.react-daterange-picker__inputGroup__input, & input.react-daterange-picker__inputGroup__input::placeholder': {
    color: theme.palette.text.primary,
  },
  '& select.react-daterange-picker__inputGroup__input': {
    '-webkit-appearance': 'none',
    '-moz-appearance': 'none',
    marginRight: '-2px',
    textIndent: '1px',
    textOverflow: '',
  },
  '& select.react-daterange-picker__inputGroup__input:has(option:checked:not([value=""]))': {
    textTransform: 'capitalize',
  },
  '& .MuiSvgIcon-root': {
    color: theme.palette.text.primary,
  },
  '& .react-daterange-picker__calendar': {
    zIndex: 12,
  },
  '&.react-daterange-picker--disabled': {
    backgroundColor: 'transparent',
    color: theme.palette.text.disabled,
    cursor: 'default',
  },
  '&.react-daterange-picker--disabled .react-daterange-picker__wrapper': {
    borderColor: theme.palette.text.disabled,
  },
  '&.react-daterange-picker--disabled select.react-daterange-picker__inputGroup__input, &.react-daterange-picker--disabled input.react-daterange-picker__inputGroup__input, &.react-daterange-picker--disabled .MuiSvgIcon-root': {
    color: theme.palette.text.disabled,
    opacity: 1,
  },
});

export const dateCalendar = (theme) => ({
  '&.react-calendar': {
    backgroundColor: theme.palette.background.paper,
    border: `2px solid ${ theme.palette.divider }`,
    borderRadius: '12px',
    boxShadow: theme.shadows[2],
    color: theme.palette.text.primary,
    fontFamily: theme.typography.fontFamily,
    marginTop: theme.spacing(.5),
  },
  '&.react-calendar button': {
    color: theme.palette.text.primary,
  },
  '&.react-calendar button:disabled': {
    backgroundColor: theme.palette.action.disabledBackground,
    color: theme.palette.text.disabled,
  },
  '&.react-calendar button:first-letter': {
    textTransform: 'capitalize',
  },
  '&.react-calendar .react-calendar__month-view__weekdays__weekday abbr': {
    textDecoration: 'none',
  },
  '&.react-calendar button, &.react-calendar .react-calendar__month-view__weekdays__weekday abbr': {
    fontSize: '12px',
  },
  '&.react-calendar .react-calendar__month-view__days__day--neighboringMonth, &.react-calendar .react-calendar__decade-view__years__year--neighboringDecade, &.react-calendar .react-calendar__century-view__decades__decade--neighboringCentury': {
    color: theme.palette.text.disabled,
  },
  '&.react-calendar .react-calendar__tile--now': {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.text.primary,
  },
  '&.react-calendar .react-calendar__tile--active, &.react-calendar .react-calendar__tile--hasActive, &.react-calendar .react-calendar__tile--hover': {
    backgroundColor: theme.palette.secondary.overlay,
    color: theme.palette.text.primary,
  },
  '&.react-calendar .react-calendar__tile--rangeStart, &.react-calendar .react-calendar__tile--rangeEnd': {
    backgroundColor: theme.palette.text.primary,
    color: theme.palette.background.paper,
  },
  '&.react-calendar button:hover': {
    backgroundColor: theme.palette.action.focus,
    color: theme.palette.text.primary,
  },
});