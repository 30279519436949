import React from 'react';

import PropTypes from 'prop-types';

import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

// import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DirectionsIcon from '@material-ui/icons/Directions';
import Grid from '@material-ui/core/Grid';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ScheduleIcon from '@material-ui/icons/Schedule';

import * as Yup from 'yup';

import { Formik, Field } from 'formik';

import { handleEndpointErrors, formatTime, getFavoriteRoutesDaysByDirection, FR_WEEKDAYS, SNACKBAR_TIME } from '../../shared/utilities';
import { useDebounce } from '../../shared/hooks/useDebounce';

import LoadingCard from '../LoadingCard';
import CustomFormikForm from '../CustomFormikForm';
import FormikRadioGroup from '../FormikRadioGroup';
import FormikAutocomplete from '../FormikAutocomplete';
import FormikDaySelector from '../FormikDaySelector';

import { useTranslation } from 'react-multi-lang';

// const useStyles = makeStyles(theme => ({
// }));

const AddFavoriteRoute = (props) => {
  const { userId, clientId, routeIds, onAddFavoriteRouteModalClose } = props;
  // const classes = useStyles();

  const store = useStore();

  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const getRoutes = useStoreActions(actions => actions.routes.getRoutes);
  const routes = useStoreState(state => state.routes.loadedRoutes);
  const routesLoading = useStoreState(state => state.routes.loading);
  const setRoutesTextSearch = useStoreActions(actions => actions.routes.setTextSearch);

  const userPlaces = useStoreState(state => state.places.userPlaces);
  const routePlaces = useStoreState(state => state.places.routePlaces);
  const placesLoading = useStoreState(state => state.places.loading);
  const getUserPlaces = useStoreActions(actions => actions.places.getUserPlaces);
  const getRoutePlaces = useStoreActions(actions => actions.places.getRoutePlaces);

  const routeSchedules = useStoreState(state => state.routes.routeSchedules);
  const routeSchedulesLoading = useStoreState(state => state.routes.routeSchedulesLoading);
  const getRouteSchedules = useStoreActions(actions => actions.routes.getRouteSchedules);
  
  const favoriteRoutes = useStoreState(state => state.users.favoriteRoutes);
  const addFavoriteRoute = useStoreActions(actions => actions.users.addFavoriteRoute);
  const getFavoriteRoutes = useStoreActions(actions => actions.users.getFavoriteRoutes);
  
  const clients = useStoreState(state => state.clients.clients);
  const client = clients.find(client => client.id === clientId);
  const allowAnyDay = client.favoriteRoutesAnyDay;
  const allowedDays = client.favoriteRoutesDays;

  const [routeName, setRouteName] = React.useState('');
  const debouncedRouteName = useDebounce(routeName, 500);
  const [defaultDirection, setDefaultDirection] = React.useState(null);
  const daysByDirection = getFavoriteRoutesDaysByDirection(favoriteRoutes, allowedDays);
  const initialDays = FR_WEEKDAYS.filter(day => allowedDays.includes(day));

  const onGetRoutes = (payload) => {
    getRoutes(payload).then(() => {
      const routesState = store.getState().routes;
      if (!routesState.loading && !routesState.error) {
        // console.log(routesState);
      } else {
        handleEndpointErrors(routesState, props, setSnackbar, t);
      }
    });
  }

  React.useEffect(() => {
    if (!defaultDirection) {
      const defaultDir = daysByDirection.isInComplete ? 'out' : 'in';
      setDefaultDirection(defaultDir);
    }

    if (defaultDirection) {
      onGetRoutes({ clientId, direction: defaultDirection, noUpdateList: true, textSearch: '', active: true, loading: true });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultDirection]);

  React.useEffect(() => {
    if (defaultDirection) {
      onGetRoutes({ clientId, direction: defaultDirection, noUpdateList: true, textSearch: debouncedRouteName, active: true });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedRouteName]);

  React.useEffect(() => {
    return () => setRoutesTextSearch('');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const t = useTranslation();

  const directionOptions = [
    { label: t('user.favoriteRoutes.direction.in'), value: 'in' },
    { label: t('user.favoriteRoutes.direction.out'), value: 'out' }
  ];

  const formRef = React.useRef();

  return (
    defaultDirection && <React.Fragment>
      <DialogTitle>{ t('user.favoriteRoutes.add.title') }</DialogTitle>
      <Formik
        innerRef={ formRef }
        initialValues={{
          direction: defaultDirection,
          route: '',
          schedule: '',
          place: '',
          weekDays: daysByDirection[defaultDirection].length ? [] : initialDays,
          daysByDirection
        }}
        validationSchema={ Yup.lazy(values => Yup.object({
          route: Yup.string()
            .required(t('global.errors.required', { field: t('user.favoriteRoutes.add.route') })),
          schedule: Yup.string()
            .required(t('global.errors.required', { field: t(`user.favoriteRoutes.${ values.route?.direction === 'out' ? 'departureTime' : 'arrivalTime' }`) })),
          place: Yup.string()
            .required(t('global.errors.required', { field: t('user.favoriteRoutes.place') })),
          weekDays: Yup.string()
            .min(1, t('user.favoriteRoutes.add.days.error'))
            .required(t('user.favoriteRoutes.add.days.error')),
        })) }
        onSubmit={(values, { setSubmitting }) => {
          const data = {
            userId,
            routeId: values.route.id,
            routeScheduleId: values.schedule.routeScheduleId,
            placeId: values.place.id,
            weekDays: values.weekDays
          };  
          addFavoriteRoute(data).then(() => {
            const usersState = store.getState().users;
            if (!usersState.loading && !usersState.error) {
              setSubmitting(false);
              getFavoriteRoutes({ userId });
              setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('global.success.saved') });
              onAddFavoriteRouteModalClose();
            } else {
              handleEndpointErrors(usersState, props, setSnackbar, t);
            }
          });
        }}
      >
        {({ submitForm, isSubmitting, values, setFieldValue }) => (
          <CustomFormikForm>
            <DialogContent>
              <Grid container spacing={ 3 }>
                <Grid item xs={ 12 }>
                  <Field
                    name="direction"
                    component={ FormikRadioGroup }
                    label={ t('user.favoriteRoutes.direction.label') }
                    options={ directionOptions }
                    disabled={ isSubmitting || daysByDirection.isInComplete || daysByDirection.isOutComplete }
                    onChange={(e) => {
                      const dir = e.target.value;
                      setDefaultDirection(dir);
                      setFieldValue('route', '');
                      setFieldValue('schedule', '');
                      setFieldValue('place', '');
                      setFieldValue('weekDays', daysByDirection[dir].length ? [] : initialDays);
                    }}
                  />
                </Grid>
                <Grid item xs={ 12 } md={ 4 }>
                  { routesLoading ? <LoadingCard length={ 1 } height={ 56 } /> : (
                    <Field
                      component={ FormikAutocomplete }
                      name='route'
                      disableClearable
                      options={ routes.filter(route => !routeIds.includes(route.id)) }
                      getOptionLabel={ option => option ? option.name : '' }
                      disabled={ isSubmitting || daysByDirection.isComplete }
                      textFieldProps={{
                        label: t('user.favoriteRoutes.add.route'),
                        variant: 'outlined',
                        fullWidth: true,
                        icon: <DirectionsIcon />,
                        onChange: event => {
                          setRouteName( event.target.value );
                        }
                      }}
                      onFocus={() => {
                        setRouteName('');
                      }}
                      onChange={() => {
                        setTimeout(() => {
                          const formValues = formRef.current.values;
                          getRouteSchedules(formValues.route.id);
                          if (!(formValues.route.routeType.name.toLowerCase() === 'dtd')) {
                            getRoutePlaces(formValues.route.id);
                          } else {
                            getUserPlaces(userId);
                          }
                        });
                        setFieldValue('schedule', '');
                        setFieldValue('place', '');
                      }}
                    />
                  ) }
                </Grid>
                <Grid item xs={ 12 } md={ 4 }>
                  <Field
                    component={ FormikAutocomplete }
                    name='schedule'
                    disableClearable
                    options={ routeSchedulesLoading ? [] : routeSchedules }
                    getOptionLabel={ option => option ? `${ defaultDirection === 'out' ? formatTime(option.departureTime) : formatTime(option.arrivalTime) }` : '' }
                    textFieldProps={{
                      label: t(`user.favoriteRoutes.${ defaultDirection === 'out' ? 'departureTime' : 'arrivalTime' }`),
                      variant: 'outlined',
                      fullWidth: true,
                      icon: <ScheduleIcon />,
                    }}
                    disabled={ !values.route }
                    noOptionsText={ routeSchedulesLoading ? t('global.noOptionsLoading') : undefined }
                    onChange={() => {
                      setFieldValue('place', '');
                    }}
                  />
                </Grid>
                <Grid item xs={ 12 } md={ 4 }>
                  <Field
                    component={ FormikAutocomplete }
                    name='place'
                    disableClearable
                    options={ values.route?.routeType?.name?.toLowerCase() === 'dtd' ? userPlaces : routePlaces }
                    getOptionLabel={ option => option ? option.description : '' }
                    textFieldProps={{
                      label: t('user.favoriteRoutes.place'),
                      variant: 'outlined',
                      fullWidth: true,
                      icon: <LocationOnIcon />,
                    }}
                    noOptionsText={ placesLoading ? t('global.noOptionsLoading') : undefined }
                    disabled={ !values.route || !values.schedule }
                  />
                </Grid>
                <Grid item xs={ 12 }>
                  <Field
                    component={ FormikDaySelector }
                    name="weekDays"
                    disabled={ isSubmitting || routesLoading || daysByDirection.isComplete }
                    allowedDays={ allowedDays }
                    allowAnyDay={ allowAnyDay }
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={ onAddFavoriteRouteModalClose } color="primary">{ t('global.modal.btnClose') }</Button>
              <Button disabled={ isSubmitting || daysByDirection.isComplete } onClick={ submitForm } color="primary">{ t('global.modal.btnAdd') }</Button>
            </DialogActions>
          </CustomFormikForm>
        )}
      </Formik>
    </React.Fragment>
  );
}

AddFavoriteRoute.propTypes = {
  userId: PropTypes.number.isRequired,
  clientId: PropTypes.number.isRequired,
  routeIds: PropTypes.array.isRequired,
  onAddFavoriteRouteModalClose: PropTypes.func.isRequired,
};

export default AddFavoriteRoute;
