import React from 'react';

import { ButtonGroup, Button, Typography, makeStyles, useMediaQuery } from '@material-ui/core';
import { CheckCircle, Block } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { useTheme } from '@material-ui/core/styles';

import { useTranslation } from 'react-multi-lang';
import clsx from 'clsx';

import { FR_DAYS_ALL, FR_DAYS, FR_WEEKDAYS } from '../shared/utilities';

// Styling for buttons and selected states
const useStyles = makeStyles((theme) => ({
  label: {
    display: 'block',
    marginBottom: theme.spacing(1),
  },
  button: {
    textTransform: 'none',
    '&:disabled': {
      backgroundColor: theme.palette.action.disabledBackground,
      color: theme.palette.text.disabled,
    },
    '& .MuiSvgIcon-root': {
      fontSize: '10px',
      position: 'absolute',
      right: '2px',
      top: '2px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '12px',
      },
    },
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  error: {
    display: 'block',
    marginTop: theme.spacing(.5),
  },
  alert: {
    marginBottom: theme.spacing(-1),
    marginTop: theme.spacing(2),
  },
}));

const FormikDaySelector = ({ field, form, disabled, label, allowedDays, allowAnyDay }) => {
  const classes = useStyles();
  const t = useTranslation();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  const { name, value } = field; // Access Formik field props
  const { setFieldValue, values, errors, touched } = form; // Access Formik helpers

  const daysOfWeek = allowAnyDay ? FR_DAYS_ALL : FR_DAYS;
  const weekdays = FR_WEEKDAYS;
  const daysByDirection = values?.daysByDirection ? values?.daysByDirection[values?.direction] : [];

  const sortedDays = days => days.sort((a, b) => (a === 0 ? 1 : b === 0 ? -1 : a - b));

  const handleToggle = (day) => {
    const updatedDays = value.includes(day)
      ? !allowAnyDay && day === 1 ? value.filter((d) => !weekdays.includes(d)) : value.filter((d) => d !== day)
      : !allowAnyDay && day === 1 ? [...value, ...weekdays] : [...value, day];
    setFieldValue(name, sortedDays(updatedDays));
  };

  const Day = ({ day }) => {
    const isWeekdays = day === 1 && !allowAnyDay;
    return isWeekdays ? (
      <React.Fragment>
        { isXs ? `${t('global.days.1').substring(0, 1)} - ${t('global.days.5').substring(0, 1)}` : `${t('global.days.1').substring(0, 3)} - ${t('global.days.5').substring(0, 3)}` }
        { value.includes(day) && <CheckCircle color="secondary" /> }
        { !value.includes(day) && !allowedDays.includes(day) && <Block color="error" /> }
      </React.Fragment>
    ) : (
      <React.Fragment>
        { isXs ? t(`global.days.${ day }`).substring(0, 1) : t(`global.days.${ day }`).substring(0, 3) }
        { value.includes(day) && <CheckCircle color="secondary" /> }
        { !value.includes(day) && !allowedDays.includes(day) && <Block color="error" /> }
      </React.Fragment>
    );
  };

  React.useEffect(() => {
    if (!allowAnyDay && [...value].some(day => weekdays.includes(day))) {
      const updatedDays = [...new Set([...value, ...weekdays])];
      setFieldValue(name, sortedDays(updatedDays));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowAnyDay]);

  return (
    <div>
      <Typography className={ classes.label } variant="overline">{ label || t('user.favoriteRoutes.add.days.label') }</Typography>
      <ButtonGroup
        size={ isXs ? 'small' : 'medium' }
        color="primary"
        aria-label="day selector"
        orientation="horizontal"
        disabled={ disabled }
      >
        {daysOfWeek.map((day) => (
          <Button
            key={day}
            className={clsx(classes.button, { [classes.selected]: value.includes(day) })}
            onClick={() => handleToggle(day)}
            disabled={ daysByDirection?.includes(day) || !allowedDays?.includes(day) }
          >
            <Day day={ day } />
          </Button>
        ))}
      </ButtonGroup>
      { errors[name] && touched[name] && (
        <Typography className={ classes.error } variant="caption" color="error">{ t('user.favoriteRoutes.add.days.error') }</Typography>
      ) }
      { !values?.daysByDirection?.isComplete && allowedDays.length < 7 && <Alert className={ classes.alert } severity="info">{ t('user.favoriteRoutes.add.days.alertDisabledNotAllowed') }</Alert> }
      { !values?.daysByDirection?.isComplete && !!daysByDirection?.length && <Alert className={ classes.alert } severity="info">{ t('user.favoriteRoutes.add.days.alertDisabled') }</Alert> }
      { values?.daysByDirection?.isComplete && <Alert className={ classes.alert } severity="info">{ t('user.favoriteRoutes.add.days.alertDisabledAllCompleted') }</Alert> }
    </div>
  );
};

export default FormikDaySelector;
