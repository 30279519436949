import React from 'react';

import PropTypes from 'prop-types';

import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

// import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';

import * as Yup from 'yup';

import { Formik, Field } from 'formik';
import { TextField } from 'formik-material-ui';

import { handleEndpointErrors, createDate, SNACKBAR_TIME } from '../../shared/utilities';

import CustomFormikForm from '../CustomFormikForm';
import FormikCheckbox from '../FormikCheckbox';
import FormikDatePicker from '../FormikDatePicker';

import { useTranslation } from 'react-multi-lang';

// const useStyles = makeStyles(theme => ({
//   root: {},
// }));

const EditOperationalInterval = (props) => {
  const { editOperationalInterval, onGetOperationalIntervals, onEditOperationalIntervalModalClose } = props;
  // const classes = useStyles();

  const store = useStore();

  const permissions = useStoreState(state => state.global.permissions);
  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const updateOperationalInterval = useStoreActions(actions => actions.operationalIntervals.updateOperationalInterval);

  const t = useTranslation();
  const canEdit = permissions?.admin?.operationalIntervals?.edit;

  return (
    <React.Fragment>
      <DialogTitle>{ canEdit ? t('operationalIntervals.table.actionEdit') : editOperationalInterval.name }</DialogTitle>
      <Formik
        initialValues={{ ...editOperationalInterval, startDate: createDate(editOperationalInterval.startDate), endDate: createDate(editOperationalInterval.endDate) }}
        validationSchema={ Yup.object({
          name: Yup.string()
            .max(50, t('global.errors.max', { field: t('operationalIntervals.name'), length: 50 }))
            .required(t('global.errors.required', { field: t('operationalIntervals.name') })),
        }) }
        onSubmit={(values, { setSubmitting }) => {
          const data = {
            name: values.name,
            startDate: createDate(values.startDate),
            endDate: createDate(values.endDate),
            monday: values.monday,
            tuesday: values.tuesday,
            wednesday: values.wednesday,
            thursday: values.thursday,
            friday: values.friday,
            saturday: values.saturday,
            sunday: values.sunday,
          };
          updateOperationalInterval({ operationalIntervalId: values.id, data }).then(() => {
            const operationalIntervalsState = store.getState().operationalIntervals;
            if (!operationalIntervalsState.loading && !operationalIntervalsState.error) {
              setSubmitting(false);
              onGetOperationalIntervals();
              setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('global.success.saved') });
              onEditOperationalIntervalModalClose();
            } else {
              handleEndpointErrors(operationalIntervalsState, props, setSnackbar, t);
            }
          });
        }}
      >
        {({ submitForm, isSubmitting, values }) => (
          <CustomFormikForm>
            <DialogContent>
              <Grid container spacing={ 3 }>
                <Grid item xs={ 12 } md={ 4 }>
                  <Field
                    component={ TextField }
                    name="name"
                    type="text"
                    label={ t('operationalIntervals.name') }
                    variant="outlined"
                    fullWidth
                    disabled={ isSubmitting || !canEdit }
                  />
                </Grid>
                <Grid item xs={ 6 } md={ 4 }>
                  <Field
                    component={ FormikDatePicker }
                    name="startDate"
                    label={ t('operationalIntervals.startDate') }
                    maxDate={ new Date(values.endDate) }
                    disabled={ isSubmitting || !canEdit }
                  />
                </Grid>
                <Grid item xs={ 6 } md={ 4 }>
                  <Field
                    component={ FormikDatePicker }
                    name="endDate"
                    label={ t('operationalIntervals.endDate') }
                    minDate={ new Date(values.startDate) }
                    disabled={ isSubmitting || !canEdit }
                  />
                </Grid>
              </Grid>
              <Grid container spacing={ 3 }>
                <Grid item xs={ 6 } md={ 3 }>
                  <Field
                    component={ FormikCheckbox }
                    name="monday"
                    label={ t('operationalIntervals.monday') }
                    disabled={ isSubmitting || !canEdit }
                  />
                </Grid>
                <Grid item xs={ 6 } md={ 3 }>
                  <Field
                    component={ FormikCheckbox }
                    name="tuesday"
                    label={ t('operationalIntervals.tuesday') }
                    disabled={ isSubmitting || !canEdit }
                  />
                </Grid>
                <Grid item xs={ 6 } md={ 3 }>
                  <Field
                    component={ FormikCheckbox }
                    name="wednesday"
                    label={ t('operationalIntervals.wednesday') }
                    disabled={ isSubmitting || !canEdit }
                  />
                </Grid>
                <Grid item xs={ 6 } md={ 3 }>
                  <Field
                    component={ FormikCheckbox }
                    name="thursday"
                    label={ t('operationalIntervals.thursday') }
                    disabled={ isSubmitting || !canEdit }
                  />
                </Grid>
                <Grid item xs={ 6 } md={ 3 }>
                  <Field
                    component={ FormikCheckbox }
                    name="friday"
                    label={ t('operationalIntervals.friday') }
                    disabled={ isSubmitting || !canEdit }
                  />
                </Grid>
                <Grid item xs={ 6 } md={ 3 }>
                  <Field
                    component={ FormikCheckbox }
                    name="saturday"
                    label={ t('operationalIntervals.saturday') }
                    disabled={ isSubmitting || !canEdit }
                  />
                </Grid>
                <Grid item xs={ 6 } md={ 3 }>
                  <Field
                    component={ FormikCheckbox }
                    name="sunday"
                    label={ t('operationalIntervals.sunday') }
                    disabled={ isSubmitting || !canEdit }
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={ onEditOperationalIntervalModalClose } color="primary">{ t('global.modal.btnClose') }</Button>
              {canEdit && <Button disabled={ isSubmitting } onClick={ submitForm } color="primary">{ t('global.modal.btnUpdate') }</Button>}
            </DialogActions>
          </CustomFormikForm>
        )}
      </Formik>
    </React.Fragment>
  );
}

EditOperationalInterval.propTypes = {
  editOperationalInterval: PropTypes.object.isRequired,
  onGetOperationalIntervals: PropTypes.func.isRequired,
  onEditOperationalIntervalModalClose: PropTypes.func.isRequired,
};

export default EditOperationalInterval;
