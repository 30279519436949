import React from 'react';

import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

import { withTheme, makeStyles } from '@material-ui/core/styles';
import CardTravelIcon from '@material-ui/icons/CardTravel';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import DirectionsIcon from '@material-ui/icons/Directions';
import Grid from '@material-ui/core/Grid';
import ReceiptIcon from '@material-ui/icons/Receipt';

import LoadingCard from '../components/LoadingCard';
import DateRange from '../components/DateRange';
import RefreshTimer from '../components/Dashboard/RefreshTimer';
import CustomCard from '../components/Dashboard/Card';
import TripsMap from '../components/Dashboard/TripsMap';
import BarChart from '../components/Dashboard/BarChart';
import RadialBarChart from '../components/Dashboard/RadialBarChart';

import { formatDate, currencyFormatter, handleEndpointErrors, DEFAULT_SNACKBAR } from '../shared/utilities';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2, 2, 3),
    '& .recharts-tooltip-item-list': {
      display: 'none',
    },
  },
  filters: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    '& > div': {
      margin: theme.spacing(1, 0),
    },
  },
  '& .MuiGrid-item': {
    minHeight: '100%',
  },
}));

const Dashboard = (props) => {
  const { theme } = props;
  const classes = useStyles();
  const [reservations, setReservations] = React.useState([]);
  const [vehicleIds, setVehicleIds] = React.useState([]);
  const [vehiclePlates, setVehiclePlates] = React.useState([]);
  const [billing, setBilling] = React.useState(0);
  const [costs, setCosts] = React.useState(0);
  const [dates, setDates] = React.useState({});
  const [newTimer, setNewTimer] = React.useState(0);

  const store = useStore();

  const clientSelected = useStoreState(state => state.global.clientSelected);
  const permissions = useStoreState(state => state.global.permissions);
  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const getDashboardData = useStoreActions(actions => actions.dashboard.getDashboardData);

  const dashboardData = useStoreState(state => state.dashboard.data);

  const canShowBillings = permissions?.admin?.dashboard?.showBillings;

  const t = useTranslation();

  const changeDateRange = ({startDate, endDate}) => {
    let start;
    let end;
    
    setSnackbar({ show: true, severity: 'info', message: t('dashboard.loading') });
    setDates(state => {
      start = startDate ? startDate : state.startDate;
      end = endDate ? endDate : state.endDate;

      getDashboardData({startDate: start.toISOString().split('T')[0], endDate: end.toISOString().split('T')[0], clientId: clientSelected}).then(() => {
        const dashboardState = store.getState().dashboard;
        setSnackbar(DEFAULT_SNACKBAR);
        if (!dashboardState.loading && !dashboardState.error) {
          setReservations(dashboardState.data.reservationsPerDay?.map(reservation => ({
            reservationsCount: reservation.count, formatDate: formatDate(reservation.date, t), date: reservation.date
          })) || []);
          setVehiclePlates(dashboardState.data.vehicles?.map(vehicle => vehicle.licensePlate) || []);
          setVehicleIds(dashboardState.data.vehicles?.map(vehicle => vehicle.identifier) || []);
        } else {
          handleEndpointErrors(dashboardState, props, setSnackbar, t);
        }
      });

      return {startDate: start, endDate: end};
    });
    setNewTimer(state => state + 1);
  };

  React.useEffect(() => {
    const dataClient = dashboardData.costPerClient?.find(client => client.clientId === +clientSelected);
    if ( clientSelected === 'all' && dashboardData.costPerClient ) {
      const allBilling = dashboardData.costPerClient.reduce(
        (previousValue, currentValue) => previousValue + (currentValue.cost * currentValue.billingRatio),
        0
      );
      const allCosts = dashboardData.costPerClient.reduce(
        (previousValue, currentValue) => previousValue + currentValue.cost,
        0
      );
      setBilling(currencyFormatter.format(allBilling));
      setCosts(currencyFormatter.format(allCosts));
    } else if (dataClient) {
      setBilling(currencyFormatter.format(dataClient.cost * dataClient.billingRatio));
      setCosts(currencyFormatter.format(dataClient.cost));
    } else {
      setBilling(currencyFormatter.format(0));
      setCosts(currencyFormatter.format(0));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardData, clientSelected]);

  React.useEffect(() => {
    if (dates.startDate && dates.endDate) {
      changeDateRange(dates.startDate, dates.endDate);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientSelected]);

  const storeMenuTitle = useStoreActions(actions => actions.global.storeTitle);

  storeMenuTitle(t('home.pageTitle'));

  return (
    <div className={ classes.root }>
      {/* <pre>{ JSON.stringify(reservations, null, 2) }</pre> */}
      <div className={ classes.filters }>
        <DateRange
          onChangeFn={ changeDateRange }
          initialDateRange="currentMonth"
          ranges={ ['today', 'last7Days', 'previousPreviousMonth', 'previousMonth', 'currentMonth', 'custom'] }
        />
        <RefreshTimer changeFunction={ () => changeDateRange(dates) } clearTimer={ newTimer } />
      </div>
      { dashboardData.id ? (
        <Grid container spacing={ 2 }>
          {!!canShowBillings && <>
            <Grid item xs={ 12 } sm={ 6 } lg={ 4 }>
              <CustomCard
                avatar={{ icon: <ReceiptIcon />, borderColor: theme.palette.primary.light, color: theme.palette.primary.main }}
                title={ t('dashboard.billing') }
                subheader={`${ billing }`}
              />
            </Grid>
            <Grid item xs={ 12 } sm={ 6 } lg={ 4 }>
              <CustomCard
                avatar={{ icon: <CardTravelIcon />, borderColor: theme.palette.primary.light, color: theme.palette.primary.dark }}
                title={ t('dashboard.costs') }
                subheader={`${ costs }`}
              />
            </Grid>
          </>}
          <Grid item xs={ 12 } sm={ 6 } lg={ canShowBillings ? 2 : 6 }>
            <CustomCard
              avatar={{ icon: <DirectionsCarIcon />, borderColor: theme.palette.primary.light, color: theme.palette.primary.main }}
              title={ t('dashboard.totalVehicles') }
              subheader={`${ dashboardData.vehiclesCount }`}
            />
          </Grid>
          <Grid item xs={ 12 } sm={ 6 } lg={ canShowBillings ? 2 : 6 }>
            <CustomCard
              avatar={{ icon: <DirectionsIcon />, borderColor: theme.palette.primary.light, color: theme.palette.primary.dark }}
              title={ t('dashboard.totalRoutes') }
              subheader={`${ dashboardData.routesCount }`}
            />
          </Grid>
          <Grid item xs={ 12 } md={ dashboardData.tripsCount > 0 ? 8 : 12 }>
            { reservations.length ? <CustomCard
              title={ t('dashboard.reservationsPerDay.title') }
              component={ <BarChart
                data={ reservations }
                xField="formatDate"
                yField="reservationsCount"
                barColor={ theme.palette.primary.main }
              /> }
              marginBottom={ 2 }
            /> : null }
            { !!vehicleIds.length && <CustomCard
              component={ <TripsMap vehicleIds={ vehicleIds } vehiclePlates={ vehiclePlates } height100={ reservations.length ? false : true } /> }
              noPadding
              height100={ reservations.length ? false : true }
            /> }
          </Grid>
          { dashboardData.tripsCount > 0 ? <Grid item xs={ 12 } md={ 4 }>
            <CustomCard
              title={ t('dashboard.trips.label') }
              component={ <RadialBarChart
                data={[
                  { name: t('dashboard.trips.totals'), value: dashboardData.tripsCount, fill: theme.palette.primary.dark },
                  { name: t('dashboard.trips.underway'), value: dashboardData.tripsUnderwayCount, fill: theme.palette.primary.main },
                  { name: t('dashboard.trips.completed'), value: dashboardData.tripsCompletedCount, fill: theme.palette.secondary.dark },
                  { name: t('dashboard.trips.delayed'), value: dashboardData.tripsDelayedCount, fill: theme.palette.secondary.main },
                ]}
                dataKey="value"
              /> }
              height100
            />
          </Grid> : null }
        </Grid>
      ) : (
        <Grid container spacing={ 2 }>
          <Grid item xs={ 12 } sm={ 6 } lg={ 4 }><LoadingCard length={ 1 } height={ 100 } /></Grid>
          <Grid item xs={ 12 } sm={ 6 } lg={ 4 }><LoadingCard length={ 1 } height={ 100 } /></Grid>
          <Grid item xs={ 12 } sm={ 6 } lg={ 2 }><LoadingCard length={ 1 } height={ 100 } /></Grid>
          <Grid item xs={ 12 } sm={ 6 } lg={ 2   }><LoadingCard length={ 1 } height={ 100 } /></Grid>
          <Grid item xs={ 12 } md={ 7 }><LoadingCard length={ 1 } height={ 500 } /></Grid>
          <Grid item xs={ 12 } md={ 5 }><LoadingCard length={ 1 } height={ 500 } /></Grid>
        </Grid>
      ) }
    </div>
  );
}

export default withTheme(Dashboard);
