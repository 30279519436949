import React from 'react';

import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
import AssessmentIcon from '@material-ui/icons/Assessment';
import HomeIcon from '@material-ui/icons/Home';
import TableChartIcon from '@material-ui/icons/TableChart';

import PivotTableUI from 'react-pivottable/PivotTableUI';
import 'react-pivottable/pivottable.css';
import TableRenderers from 'react-pivottable/TableRenderers';
import Plot from 'react-plotly.js';
import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers';

import Breadcrumbs from '../../components/Breadcrumbs';
import LoadingCard from '../../components/LoadingCard';
import DateRange from '../../components/DateRange';

import { useTranslation } from 'react-multi-lang';

import { handleEndpointErrors, formatDateYear } from '../../shared/utilities';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
  },
}));

const ReportsReservationsPivotTable = (props) => {
  const classes = useStyles();

  const store = useStore();

  const storeMenuTitle = useStoreActions(actions => actions.global.storeTitle);
  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const reservations = useStoreState(actions => actions.reservations.reservationsReportPivot);
  const getReservations = useStoreActions(actions => actions.reservations.getReservationsReportPivot);
  const reservationsLoading = useStoreState(state => state.reservations.loading);

  const setReservationsDates = useStoreActions(actions => actions.reservations.setReservationsReportPivotDates);

  const [reservationsData, setReservationsData] = React.useState([]);
  const [reservationsPivotData, setReservationsPivotData] = React.useState({});

  const PlotlyRenderers = createPlotlyRenderers(Plot);

  const onGetReservations = (payload) => {
    setReservationsDates(payload);
    getReservations({...payload, loading: true }).then(() => {
      const reservationsState = store.getState().reservations;
      if (!reservationsState.loading && !reservationsState.error) {
        // console.log(reservationsState.reservationsReportPivot);
      } else {
        handleEndpointErrors(reservationsState, props, setSnackbar, t);
      }
    });
  };

  React.useEffect(() => {
    setReservationsData(reservations.map(reservation => {
      reservation.formatDate = formatDateYear(reservation.date, t);
      return {
        client: reservation.client,
        date: formatDateYear(reservation.date, t),
        direction: reservation.direction,
        driver: reservation.driver,
        month: reservation.month,
        passenger: reservation.passenger,
        username: reservation.username,
        functionalArea: reservation.functionalArea,
        place: reservation.place,
        route: reservation.route,
        status: reservation.status,
        extra: reservation.extra ? t('trips.addTripModal.addTrip.extra.true') : t('trips.addTripModal.addTrip.extra.false'),
        ticket: reservation.ticket,
        tripStatus: reservation.tripStatus,
        weekOfYear: reservation.weekOfYear,
        source: reservation.source,
      };
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservations]);

  const t = useTranslation();

  storeMenuTitle(t('reports.pageTitle'));

  return <div className={ classes.root }>
    <Breadcrumbs links={[
      { name: t('home.pageTitle'), path: '/', icon: <HomeIcon /> },
      { name: t('reports.pageTitle'), icon: <AssessmentIcon /> },
      { name: t('reports.reservationsPivotTable.pageTitle'), icon: <TableChartIcon /> },
    ]} />

    <DateRange
      onChangeFn={ onGetReservations }
      initialDateRange="last7Days"
      ranges={ ['last7Days', 'previousPreviousMonth', 'previousMonth', 'currentMonth', 'custom'] }
      isManualTrigger
    />

    { reservationsLoading ? <LoadingCard length={ 1 } height={ 658 } /> : (
      <PivotTableUI
        data={ reservationsData }
        rows={ ['passenger'] }
        cols={ ['month'] }
        // rendererName="Grouped Column Chart"
        onChange={s => {
          delete s.data;
          setReservationsPivotData(s);
        }}
        renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
        unusedOrientationCutoff={ Infinity }
        { ...reservationsPivotData }
      />
    ) }
  </div>;
}

export default ReportsReservationsPivotTable;
