import React from 'react';

import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import DateRangeIcon from '@material-ui/icons/DateRange';
import Typography from '@material-ui/core/Typography';

import ReactDatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

import { dateInput, dateCalendar } from '../shared/dateStyles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'inline-block',
    position: 'relative',
    width: '100%',
  },
  label: {
    background: theme.palette.background.default,
    left: '10px',
    paddingLeft: '4px',
    paddingRight: '4px',
    position: 'absolute',
    top: '-9px',
    zIndex: 1,
  },
  dateInput: {
    ...dateInput(theme),
    width: '100%',
  },
  dateCalendar: dateCalendar(theme),
}));

const FormikDatePicker = ({ field, form, label, minDate, maxDate, disabled }) => {
  const { name, value } = field;
  const { setFieldValue } = form;

  const [isCalendarOpen, setIsCalendarOpen] = React.useState(false);
  const [lastReasonTime, setLastReasonTime] = React.useState(new Date().getTime());
  const [openEl, setOpenEl] = React.useState(null);

  const classes = useStyles(value || '');

  const handleDateChange = (date) => {
    setFieldValue(name, date || null);
  };

  return (
    <div className={clsx(classes.root, 'date-picker')}>
      <Typography className={ classes.label } variant="caption" color="textSecondary">{ label }</Typography>
      <ReactDatePicker
        minDate={ minDate }
        maxDate={ maxDate }
        className={classes.dateInput}
        calendarClassName={classes.dateCalendar}
        onChange={handleDateChange}
        value={value || null}
        format="MMM dd, y"
        dayPlaceholder="dd"
        monthPlaceholder="mm, "
        yearPlaceholder="yyyy"
        clearIcon={ null }
        calendarIcon={<DateRangeIcon />}
        disabled={ disabled }
        isOpen={isCalendarOpen}
        locale={localStorage.getItem('language') === 'en' ? 'en-EN' : 'es-EN'}
        onCalendarClose={() => setIsCalendarOpen(false)}
        onCalendarOpen={() => setIsCalendarOpen(true)}
        onClick={(event) => {
          const el = event.target.querySelector('.react-date-picker__calendar-button');
          if (el && !isCalendarOpen && !openEl) {
            setOpenEl(el);
            el.click();
          } else if (openEl) {
            openEl.click();
          }
        }}
        shouldCloseCalendar={(action) => {
          setLastReasonTime(new Date().getTime());
          const timePassedInSeconds = (new Date().getTime() - lastReasonTime) / 1000;
          const shouldClose = timePassedInSeconds >= 0.1 && ['escape', 'outsideAction', 'select'].includes(action.reason);
          if (shouldClose) {
            setOpenEl(null);
          }
          return shouldClose;
        }}
      />
    </div>
  );
};

export default FormikDatePicker;
