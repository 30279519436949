import React from 'react';
import { withRouter } from 'react-router-dom';

import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Divider from '@material-ui/core/Divider';
import EditIcon from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GroupIcon from '@material-ui/icons/Group';
import HomeIcon from '@material-ui/icons/Home';
import Typography from '@material-ui/core/Typography';

import { handleEndpointErrors } from '../shared/utilities';

import BasicInformation from '../components/EditUser/BasicInformation';
import Breadcrumbs from '../components/Breadcrumbs';
import ChangePassword from '../components/EditUser/ChangePassword';
import CustomAccordion from '../components/CustomAccordion';
import Devices from '../components/EditUser/Devices';
import FavoriteRoutes from '../components/EditUser/FavoriteRoutes';
import LoadingCard from '../components/LoadingCard';
import Places from '../components/EditUser/Places';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
    '& .MuiDivider-root': {
      margin: theme.spacing(4, 0, 4),
    },
    '& .MuiAccordionDetails-root': {
      overflow: 'hidden',
      padding: 0,
      '& > div': {
        border: 'none',
        marginBottom: '-1px',
        width: '100%',
      },
      '& .MuiToolbar-root': {
        borderBottom: `1px solid ${ theme.palette.primary.light }`,
      },
    },
  },
  content: {
    position: 'relative',
    '& .formik-checkbox': {
      margin: 0,
      top: 0,
    },
    '& ul': {
      columnCount: 2,
      listStyle: 'none',
      margin: 0,
      padding: 0,
    },
    '& li': {
      ...theme.typography.caption,
      color: theme.palette.text.secondary,
    },
  },
  title: {
    marginBottom: theme.spacing(2.5),
  },
  accordionHeading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const EditUser = (props) => {
  const classes = useStyles();

  const store = useStore();

  const storeMenuTitle = useStoreActions(actions => actions.global.storeTitle);
  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const user = useStoreState(state => state.users.user);
  const getUser = useStoreActions(actions => actions.users.getUser);
  const storeGetUser = useStoreActions(actions => actions.users.storeGetUser);

  const clients = useStoreState(state => state.global.clients);

  const onGetUser = () => {
    getUser(props.match.params.id).then(() => {
      const usersState = store.getState().users;
      if (!usersState.loading && !usersState.error) {
        // console.log(usersState);
      } else {
        handleEndpointErrors(usersState, props, setSnackbar, t);
      }
    });
  };

  React.useEffect(() => {
    onGetUser();

    return () => {
      storeGetUser({});
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const t = useTranslation();

  storeMenuTitle(t('user.pageTitle'));

  return (
    <div className={ classes.root }>
      <Breadcrumbs links={[
        { name: t('home.pageTitle'), path: '/', icon: <HomeIcon /> },
        { name: t('users.pageTitle'), path: '/users', icon: <GroupIcon /> },
        { name: t('user.pageTitle'), icon: <EditIcon /> },
      ]} />
      <div className={ classes.content }>
        <Typography className={ classes.title } variant="h5">{ t('user.basicInformation.title') }</Typography>
        { (!user.id || !clients.length) ? <LoadingCard length={ 1 } height={ 250 } /> : <BasicInformation user={ user } onGetUser={ onGetUser } /> }
        <Divider />
        <CustomAccordion>
          <AccordionSummary expandIcon={ <ExpandMoreIcon /> }>
            <Typography className={ classes.accordionHeading }>{ t('user.places.title') }</Typography>
          </AccordionSummary>
          <AccordionDetails>
            { !user.id ? <LoadingCard length={ 1 } height={ 150 } /> : <Places userId={ user.id } /> }
          </AccordionDetails>
        </CustomAccordion>
        <CustomAccordion>
          <AccordionSummary expandIcon={ <ExpandMoreIcon /> }>
            <Typography className={ classes.accordionHeading }>{ t('user.devices.title') }</Typography>
          </AccordionSummary>
          <AccordionDetails>
            { !user.id ? <LoadingCard length={ 1 } height={ 150 } /> : <Devices userId={ user.id } /> }
          </AccordionDetails>
        </CustomAccordion>
        { user.role === 'passenger' && <CustomAccordion>
          <AccordionSummary expandIcon={ <ExpandMoreIcon /> }>
            <Typography className={ classes.accordionHeading }>{ t('user.favoriteRoutes.title') }</Typography>
          </AccordionSummary>
          <AccordionDetails>
            { !user.id ? <LoadingCard length={ 1 } height={ 150 } /> : <FavoriteRoutes userId={ user.id } clientId={ user.clientUser.clientId } /> }
          </AccordionDetails>
        </CustomAccordion>}
        <Divider />
        <Typography className={ classes.title } variant="h5">{ t('user.changePassword.title') }</Typography>
        { !user.id ? <LoadingCard length={ 1 } height={ 250 } /> : <ChangePassword userId={ user.id } /> }
      </div>
    </div>
  );
}

export default withRouter(EditUser);
