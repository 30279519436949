import React from 'react';
import { withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';

import clsx from 'clsx';

import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import InfoIcon from '@material-ui/icons/Info';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';

import * as Yup from 'yup';

import { Formik, Field } from 'formik';
import { TextField } from 'formik-material-ui';

import { handleEndpointErrors, FR_WEEKDAYS, SNACKBAR_TIME } from '../../shared/utilities';

import CustomFormikForm from '../CustomFormikForm';
import FormikCheckbox from '../FormikCheckbox';
import FormikAutocomplete from '../FormikAutocomplete';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  clientActive: {
    right: theme.spacing(3),
  },
}));

const AddClient = (props) => {
  const { editClient, onGetClients, onAddClientModalClose } = props;
  const classes = useStyles();

  const store = useStore();

  const organizationTypes = useStoreState(state => state.global.organizationTypes);
  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const businessParks = useStoreState(state => state.businessParks.businessParks);

  const addClient = useStoreActions(actions => actions.clients.addClient);
  const updateClient = useStoreActions(actions => actions.clients.updateClient);
  const setClientsUpdated = useStoreActions(actions => actions.clients.setClientsUpdated);

  const t = useTranslation();

  return (
    <React.Fragment>
      <DialogTitle>{ t(`clients.table.${ editClient?.id ? 'actionEdit' : 'actionAdd' }`) }</DialogTitle>
      <Formik
        initialValues={{
          ...editClient,
          active: editClient?.active === undefined ? true : editClient?.active,
          name: editClient?.name || '',
          description: editClient?.description || '',
          category: editClient?.category || '',
          billingRatio: editClient?.billingRatio || '',
          park: businessParks.find(park => editClient?.parkId === park.id) || '',
          rsvCreateHours: editClient?.rsvCreateHours || 0,
          rsvCancelHours: editClient?.rsvCancelHours || 0,
          rsvAllowDelete: editClient?.rsvAllowDelete === undefined ? true : editClient?.rsvAllowDelete,
          rsvAutoCreate: editClient?.rsvAutoCreate || false,
          rsvAllowWithQR: editClient?.rsvAllowWithQR === undefined ? true : editClient?.rsvAllowWithQR,
        }}
        validationSchema={ Yup.object({
          name: Yup.string()
            .max(50, t('global.errors.max', { field: t('clients.name'), length: 50 }))
            .required(t('global.errors.required', { field: t('clients.name') })),
          description: Yup.string()
            .max(100, t('global.errors.max', { field: t('clients.description'), length: 100 }))
            .required(t('global.errors.required', { field: t('clients.description') })),
          category: Yup.string()
            .required(t('global.errors.required', { field: t('clients.category') })),
          billingRatio: Yup.number()
            .typeError(t('global.errors.number', { field: t('clients.billingRatio') })),
          rsvCreateHours: Yup.number()
            .typeError(t('global.errors.number', { field: t('clients.rsvCreateHours') })),
          rsvCancelHours: Yup.number()
            .typeError(t('global.errors.number', { field: t('clients.rsvCancelHours') }))
        }) }
        onSubmit={(values, { setSubmitting }) => {
          const data = {
            active: values.active,
            name: values.name,
            description: values.description,
            category: values.category,
            billingRatio: +values.billingRatio,
            parkId: values.park?.id || null,
            rsvCreateHours: values.rsvCreateHours || 0,
            rsvCancelHours: values.rsvCancelHours || 0,
            rsvAllowDelete: values.rsvAllowDelete,
            rsvAutoCreate: values.rsvAutoCreate,
            rsvAllowWithQR: values.rsvAllowWithQR,
            favoriteRoutesDays: FR_WEEKDAYS
          };
          if (values?.id) {
            updateClient({ clientId: values.id, data }).then(() => {
              const clientsState = store.getState().clients;
              if (!clientsState.loading && !clientsState.error) {
                setSubmitting(false);
                onGetClients();
                setClientsUpdated(true);
                setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('global.success.saved') });
                onAddClientModalClose();
              } else {
                handleEndpointErrors(clientsState, props, setSnackbar, t);
              }
            });
          } else {
            addClient(data).then(() => {
              const clientsState = store.getState().clients;
              if (!clientsState.loading && !clientsState.error) {
                setSubmitting(false);
                setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('global.success.saved') });
                props.history.push(`/clients/${ clientsState.newClientId }`);
              } else {
                handleEndpointErrors(clientsState, props, setSnackbar, t);
              }
            });
          }
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <CustomFormikForm>
            <DialogContent>
              <Field
                component={ FormikCheckbox }
                className={clsx( 'formik-checkbox', classes.clientActive )}
                name="active"
                label={ t('clients.active') }
                disabled={ isSubmitting }
              />
              <Grid container spacing={ 3 }>
                <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                  <Field
                    component={ TextField }
                    name="name"
                    type="text"
                    label={ t('clients.name') }
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                  <Field
                    component={ TextField }
                    name="description"
                    type="text"
                    label={ t('clients.description') }
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                  <Field
                    component={ FormikAutocomplete }
                    name="category"
                    disableClearable
                    options={ organizationTypes }
                    getOptionLabel={ option => option ? t(`global.organizationTypes.${ option.toLowerCase() }`) : '' }
                    textFieldProps={{ label: t('clients.category') , variant: 'outlined', readOnly: true, fullWidth: true }}
                  />
                </Grid>
                <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                  <Field
                    component={ TextField }
                    name="billingRatio"
                    type="text"
                    label={ t('clients.billingRatio') }
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                  <Field
                    component={ TextField }
                    name="rsvCreateHours"
                    type="text"
                    label={ t('clients.rsvCreateHours') }
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip title={ t('clients.rsvCreateHoursHelper') }><InfoIcon color="disabled" /></Tooltip>
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                  <Field
                    component={ TextField }
                    name="rsvCancelHours"
                    type="text"
                    label={ t('clients.rsvCancelHours') }
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip title={ t('clients.rsvCancelHoursHelper') }><InfoIcon color="disabled" /></Tooltip>
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                  <Field
                    component={ FormikAutocomplete }
                    name="park"
                    options={ businessParks }
                    getOptionLabel={ option => option ? option.name : '' }
                    textFieldProps={{ label: t('clients.businessPark') , variant: 'outlined', readOnly: true, fullWidth: true }}
                  />
                </Grid>
                <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                  <Field
                    component={ FormikCheckbox }
                    name="rsvAllowDelete"
                    label={ t('clients.rsvAllowDelete') }
                    disabled={ isSubmitting }
                  />
                </Grid>
                <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                  <Field
                    component={ FormikCheckbox }
                    name="rsvAutoCreate"
                    label={ t('clients.rsvAutoCreate') }
                    disabled={ isSubmitting }
                  />
                </Grid>
                <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                  <Field
                    component={ FormikCheckbox }
                    name="rsvAllowWithQR"
                    label={ t('clients.rsvAllowWithQR') }
                    disabled={ isSubmitting }
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={ onAddClientModalClose } color="primary">{ t('global.modal.btnClose') }</Button>
              <Button disabled={ isSubmitting } onClick={ submitForm } color="primary">{ t(`global.modal.${ editClient?.id ? 'btnUpdate' : 'btnAdd' }`) }</Button>
            </DialogActions>
          </CustomFormikForm>
        )}
      </Formik>
    </React.Fragment>
  );
}

AddClient.propTypes = {
  editClient: PropTypes.object,
  onGetClients: PropTypes.func.isRequired,
  onAddClientModalClose: PropTypes.func.isRequired,
};

export default withRouter(AddClient);
