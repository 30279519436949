import React from 'react';

import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
import AssessmentIcon from '@material-ui/icons/Assessment';
import HomeIcon from '@material-ui/icons/Home';
import LocalActivityIcon from '@material-ui/icons/LocalActivity';

import Breadcrumbs from '../../components/Breadcrumbs';
import LoadingCard from '../../components/LoadingCard';
import DataFilters from '../../components/DataFilters';
import DateRange from '../../components/DateRange';
import Table from '../../components/Table';

import { useTranslation } from 'react-multi-lang';

import { handleEndpointErrors, getReservationStatus, getTripStatus, getRouteDirections, formatDateYear } from '../../shared/utilities';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  table: {
    '& .MuiTableCell-head': {
      minWidth: '145px',
    },
  }
}));

const ReportsReservations = (props) => {
  const classes = useStyles();

  const store = useStore();

  const storeMenuTitle = useStoreActions(actions => actions.global.storeTitle);
  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const reservationsReport = useStoreState(actions => actions.reservations.reservationsReport);
  const getReservationsReport = useStoreActions(actions => actions.reservations.getReservationsReport);
  const reservationsLoading = useStoreState(state => state.reservations.loading);
  const reservationsTextSearch = useStoreState(state => state.reservations.reservationsReportTextSearch);

  const setReservationsReportDates = useStoreActions(actions => actions.reservations.setReservationsReportDates);

  const routeDirections = useStoreState(state => state.global.routeDirections);

  const paginationPage = useStoreState(state => state.reservations.reservationsReportPage);
  const paginationPageSize = useStoreState(state => state.reservations.reservationsReportPageSize);
  const [dataLoading, setDataLoading] = React.useState(false);

  const [reservationsTableData, setReservationsTableData] = React.useState([]);
  const [datePayload, setDatePayload] = React.useState({ startDate: new Date(), endDate: new Date() });
  const [activeFilters, setActiveFilters] = React.useState({});
  const [firstTimeCall, setFirstTimeCall] = React.useState(true);

  const t = useTranslation();

  const reservationsTableColumns = localStorage.getItem('tableReservationsReport') ?
    JSON.parse(localStorage.getItem('tableReservationsReport')) : [
    { title: t('reports.reservations.table.date'), field: 'date', sorting: false },
    { title: t('reports.reservations.table.route'), field: 'route', sorting: false },
    { title: t('reports.reservations.table.direction'), field: 'direction', lookup: getRouteDirections(routeDirections, t), sorting: false },
    { title: t('reports.reservations.table.place'), field: 'place' },
    { title: t('reports.reservations.table.passenger'), field: 'passenger' },
    { title: t('reports.reservations.table.status'), field: 'status', lookup: getReservationStatus(t), sorting: false },
    { title: t('reports.reservations.table.tripStatus'), field: 'tripStatus', lookup: getTripStatus(t), sorting: false },
    { title: t('reports.reservations.table.extra'), field: 'extra', type: 'boolean', sorting: false },
    { title: t('reports.reservations.table.source'), field: 'source', sorting: false },
    { title: t('reports.reservations.table.month'), field: 'month', sorting: false, hidden: true },
    { title: t('reports.reservations.table.weekOfYear'), field: 'weekOfYear', sorting: false, hidden: true },
    { title: t('reports.reservations.table.ticket'), field: 'ticket', sorting: false, hidden: true },
    { title: t('reports.reservations.table.driver'), field: 'driver', sorting: false, hidden: true },
  ];

  storeMenuTitle(t('reports.pageTitle'));

  const getReservations = (payload) => {
    setReservationsReportDates(payload.startDate ? payload : datePayload);
    setDatePayload(payload.startDate ? payload : datePayload);
    setDataLoading(true);
    getReservationsReport({ ...activeFilters, ...payload, loading: firstTimeCall }).then(() => {
      const reservationsState = store.getState().reservations;

      if ( firstTimeCall ) {
        setFirstTimeCall(false);
      }

      if (!reservationsState.loading && !reservationsState.error) {
        // console.log(reservationsState);
        setDataLoading(false);
      } else {
        handleEndpointErrors(reservationsState, props, setSnackbar, t);
      }
    });
  };

  React.useEffect(() => {
    setReservationsTableData(reservationsReport.map(reservation => {
      reservation.formatDate = formatDateYear(reservation.date, t);
      return {
        ...reservation,
        date: formatDateYear(reservation.date, t),
      };
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservationsReport]);

  const reservationStatusOptions = Object.keys(getReservationStatus(t)).map(status => ({ label: getReservationStatus(t)[status], value: status }));
  const tripStatusOptions = Object.keys(getTripStatus(t)).map(status => ({ label: getTripStatus(t)[status], value: status }));

  return <div className={ classes.root }>
    <Breadcrumbs links={[
      { name: t('home.pageTitle'), path: '/', icon: <HomeIcon /> },
      { name: t('reports.pageTitle'), icon: <AssessmentIcon /> },
      { name: t('reservations.pageTitle'), icon: <LocalActivityIcon /> },
    ]} />

    <DataFilters
      setActiveFilters={ setActiveFilters }
      filters={[
        {
          type: 'text',
          name: t('reports.reservations.searchPlaceholder'),
          property: 'textSearch',
          active: reservationsTextSearch,
          width: '250px',
          onChangeFn: (filters) => {
            getReservations({ page: 1, ...filters });
          }
        },
        {
          type: 'select',
          multiple: true,
          name: t('reports.reservations.table.status'),
          property: 'status',
          options: reservationStatusOptions,
          active: [],
          onChangeFn: (filters) => {
            getReservations({ page: 1, ...filters });
          }
        },
        {
          type: 'select',
          multiple: true,
          width: '165px',
          name: t('reports.reservations.table.tripStatus'),
          property: 'tripStatus',
          options: tripStatusOptions,
          active: [],
          onChangeFn: (filters) => {
            getReservations({ page: 1, ...filters });
          }
        },
        {
          type: 'select',
          name: t('reports.reservations.table.extra'),
          property: 'extra',
          options: [{label: t('dataFilters.yes'), value: 'true'}, {label: t('dataFilters.no'), value: 'false'}],
          active: '',
          onChangeFn: (filters) => {
            getReservations({ page: 1, ...filters });
          }
        },
      ]}
    >
      <DateRange
        onChangeFn={ getReservations }
        initialDateRange="last7Days"
        ranges={ ['last7Days', 'last30Days', 'last3Months', 'custom'] }
      />
    </DataFilters>

    { reservationsLoading ? <LoadingCard length={ 1 } height={ 1565 } /> : (
      <Table
        className={ classes.table }
        title=""
        columns={ reservationsTableColumns }
        data={ reservationsTableData }
        exportButton
        exportFileName={ t('reports.reservations.exportFileName', {
          startDate: `${ t(`global.months.${ datePayload.startDate.getMonth() }`).toLowerCase() }-${ datePayload.startDate.getDate() }-${ datePayload.startDate.getFullYear() }`,
          endDate: `${ t(`global.months.${ datePayload.endDate.getMonth() }`).toLowerCase() }-${ datePayload.endDate.getDate() }-${ datePayload.endDate.getFullYear() }`,
        }) }
        updateColumns="ReservationsReport"
        search={ false }
        customPagination={{
          page: paginationPage,
          size: paginationPageSize,
          responseLength: reservationsReport.length,
          loading: dataLoading,
          clickEvent: page => {
            getReservations({ page, ...activeFilters });
          }
        }}
      />
    ) }
  </div>;
}

export default ReportsReservations;
