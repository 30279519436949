import React from 'react';
import { withRouter } from 'react-router-dom';

import { useStoreState, useStoreActions, useStore } from 'easy-peasy';

import { makeStyles } from '@material-ui/core/styles';
import HomeIcon from '@material-ui/icons/Home';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { handleEndpointErrors, openLink } from '../shared/utilities';

import Breadcrumbs from '../components/Breadcrumbs';
import LoadingCard from '../components/LoadingCard';
import Table from '../components/Table';
import CustomDialog from '../components/CustomDialog';
import AddClient from '../components/Clients/AddClient';
import DataFilters from '../components/DataFilters';

import { useTranslation } from 'react-multi-lang';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  table: {
    '& .MuiTableCell-head:nth-child(3), & .MuiTableCell-head:nth-child(4)': {
      minWidth: '135px',
    },
    '& .MuiTableCell-head:nth-child(5), & .MuiTableCell-head:nth-child(6)': {
      minWidth: '160px',
    },
    '& .MuiTableCell-head:nth-child(7), & .MuiTableCell-head:nth-child(8)': {
      minWidth: '200px',
    },
    '& .MuiTableCell-body .MuiFormControl-root': {
      width: '100%',
    },
  }
}));

const Clients = (props) => {
  const { history } = props;
  const classes = useStyles();

  const [clientsTableData, setClientsTableData] = React.useState([]);
  const [addClientModalOpen, setAddClientModalOpen] = React.useState(false);
  const [activeFilters, setActiveFilters] = React.useState({});

  const store = useStore();

  const permissions = useStoreState(state => state.global.permissions);
  const storeMenuTitle = useStoreActions(actions => actions.global.storeTitle);
  const setSnackbar = useStoreActions(actions => actions.global.setSnackbar);

  const businessParks = useStoreState(state => state.businessParks.businessParks);

  const clients = useStoreState(state => state.clients.clients);
  const getClients = useStoreActions(actions => actions.clients.getClients);
  // const deleteClient = useStoreActions(actions => actions.clients.deleteClient);
  const clientsLoading = useStoreState(state => state.clients.loading);
  // const storeGetClients = useStoreActions(actions => actions.clients.storeGetClients);
  // const setClientsUpdated = useStoreActions(actions => actions.clients.setClientsUpdated);
  const clientsTextSearch = useStoreState(state => state.clients.textSearch);
  const setClientsTextSearch = useStoreActions(actions => actions.clients.setTextSearch);

  const setClientsDisabled = useStoreActions(actions => actions.global.setClientsDisabled);

  const paginationPage = useStoreState(state => state.clients.page);
  const paginationPageSize = useStoreState(state => state.clients.pageSize);
  const [dataLoading, setDataLoading] = React.useState(false);

  const canEdit = permissions?.admin?.clients?.edit;
  // const canDelete = permissions?.admin?.clients?.delete;

  const t = useTranslation();

  storeMenuTitle(t('clients.pageTitle'));

  const onGetClients = (payload) => {
    setDataLoading(true);
    getClients(payload).then(() => {
      const clientsState = store.getState().clients;
      if (!clientsState.loading && !clientsState.error) {
        setDataLoading(false);
        setClientsTableData(clientsState.clients);
      } else {
        handleEndpointErrors(clientsState, props, setSnackbar, t);
      }
    });
  }

  React.useEffect(() => {
    onGetClients({ loading: true });

    return () => setClientsTextSearch('');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setClientsDisabled(true);

    return () => setClientsDisabled(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAddClientModalClose = () => {
    setAddClientModalOpen(false);
  };

  // const onDeleteClient = (oldData, resolve, reject) => {
  //   deleteClient(oldData.id).then(() => {
  //     const clientsState = store.getState().clients;
  //     if (!clientsState.error) {
  //       // console.log('success', clientsState);
  //       resolve();
  //       const data = [...clientsTableData].filter(client => client.id !== oldData.id);
  //       setClientsTableData(data);
  //       storeGetClients(clientsState.clients.filter(client => client.id !== oldData.id));
  //       setClientsUpdated(true);
  //       setSnackbar({ show: true, autoHideDuration: SNACKBAR_TIME.SUCCESS, severity: 'success', message: t('global.success.saved') });
  //     } else {
  //       reject();
  //       handleEndpointErrors(clientsState, props, setSnackbar, t);
  //     }
  //   });
  // };

  const clientsTableColumns = [
    { title: t('clients.id'), field: 'id', type: 'numeric', align: 'left', defaultSort: 'asc', editable: 'never' },
    { title: t('clients.name'), field: 'name', editable: 'never' },
    { title: t('clients.businessPark'), field: 'parkId', render: rowData => <span>{businessParks.find(park => park.id === rowData.parkId)?.name}</span>, sorting: false, editable: 'never' },
    { title: t('clients.active'), field: 'active', type: 'boolean', sorting: false, editable: 'never' },  
    { title: t('clients.rsvAllowDelete'), field: 'rsvAllowDelete', type: 'boolean', sorting: false, editable: 'never' },
    { title: t('clients.rsvAutoCreate'), field: 'rsvAutoCreate', type: 'boolean', sorting: false, editable: 'never' },
    { title: t('clients.rsvAllowWithQR'), field: 'rsvAllowWithQR', type: 'boolean', sorting: false, editable: 'never' },
    { title: t('clients.favoriteRoutes.favoriteRoutesAllowCreate'), field: 'favoriteRoutesAllowCreate', type: 'boolean', sorting: false, editable: 'never' },
    { title: t('clients.favoriteRoutes.favoriteRoutesAllowDelete'), field: 'favoriteRoutesAllowDelete', type: 'boolean', sorting: false, editable: 'never' },
  ];

  return (
    <div className={ classes.root }>
      <Breadcrumbs links={[
        { name: t('home.pageTitle'), path: '/', icon: <HomeIcon /> },
        { name: t('clients.pageTitle'), icon: <RecentActorsIcon /> },
      ]} />
      <DataFilters
        setActiveFilters={ setActiveFilters }
        filters={[
          {
            type: 'text',
            name: t('clients.searchPlaceholder'),
            property: 'textSearch',
            active: clientsTextSearch,
            width: '250px',
            onChangeFn: (filters) => {
              onGetClients({ page: 1, ...filters });
            }
        },
        ]}
      />
      { clientsLoading ? <LoadingCard length={ 1 } height={ 430 } /> : (
        <Table
          className={ classes.table }
          title=""
          columns={ clientsTableColumns }
          data={ clientsTableData }
          filtering
          exportButton
          exportFileName={ t('clients.pageTitle') }
          // deleteEvent={ canDelete ? onDeleteClient : undefined }
          search={ false }
          customPagination={{
            page: paginationPage,
            size: paginationPageSize,
            responseLength: clients.length,
            loading: dataLoading,
            clickEvent: page => {
              onGetClients({ page, ...activeFilters });
            }
          }}
          addActions={[
            ...canEdit ? [{
              icon: 'add',
              iconProps: {
                color: 'primary'
              },
              tooltip: t('clients.table.actionAdd'),
              isFreeAction: true,
              onClick: () => {
                setAddClientModalOpen(true);
              }
            }] : [],
            {
              icon: canEdit ? 'edit' : () => <VisibilityIcon />,
              tooltip: canEdit ? t('clients.table.actionEdit') : t('clients.table.actionView'),
              onClick: (event, rowData) => {
                openLink(event, history, `/clients/${ rowData.id }`);
              }
            }
          ]}
        />
      ) }

      <CustomDialog open={ addClientModalOpen } onClose={ onAddClientModalClose } fullWidth={ true } maxWidth="md">
        <AddClient
          onGetClients={ onGetClients }
          onAddClientModalClose={ onAddClientModalClose }
        />
      </CustomDialog>
    </div>
  );
}

export default withRouter(Clients);
