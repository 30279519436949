import React from 'react';

import PropTypes from 'prop-types';

import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import DateRangeIcon from '@material-ui/icons/DateRange';

import ReactDatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

import { transformDate } from '../shared/utilities';
import { dateInput, dateCalendar } from '../shared/dateStyles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'inline-block',
  },
  dateInput: {
    ...dateInput(theme),
    '& .react-date-picker__clear-button': {
      visibility: (value) => value ? 'visible' : 'hidden',
    },
  },
  dateCalendar: dateCalendar(theme),
}));

const DatePicker = (props) => {
  const { value, onChange } = props;

  const [isCalendarOpen, setIsCalendarOpen] = React.useState(false);
  const [lastReasonTime, setLastReasonTime] = React.useState(new Date().getTime());
  const [openEl, setOpenEl] = React.useState(null);

  const classes = useStyles(value || '');

  return <div className={clsx( classes.root, 'date-picker' )}>
    <ReactDatePicker
      className={ classes.dateInput }
      calendarClassName={ classes.dateCalendar }
      onChange={ onChange }
      value={ value ? transformDate(value.toISOString()) : null }
      format='MMM dd, y'
      dayPlaceholder='dd'
      monthPlaceholder='mm, '
      yearPlaceholder="yyyy"
      clearIcon={ <ClearIcon /> }
      calendarIcon={ <DateRangeIcon /> }
      isOpen={ isCalendarOpen }
      locale={ localStorage.getItem('language') === 'en' ? 'en-EN' : 'es-EN' }
      onCalendarClose={ () => setIsCalendarOpen(false) } 
      onCalendarOpen={ () => setIsCalendarOpen(true) }
      onClick={(event) => {
        const el = event.target.querySelector('.react-date-picker__calendar-button');
        if (el && !isCalendarOpen && !openEl) {
          setOpenEl(el);
          el.click();
        } else if (openEl) {
          openEl.click();
        }
      } }
      shouldCloseCalendar={ action => {
        setLastReasonTime(new Date().getTime());
        const timePassedInSeconds = (new Date().getTime() - lastReasonTime) / 1000;
        const shouldClose = timePassedInSeconds >= 0.1 && ['escape', 'outsideAction', 'select'].includes(action.reason);
        if (shouldClose) {
          setOpenEl(null);
        }
        return shouldClose;
      } }
    />
  </div>;
}

DatePicker.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
};

export default DatePicker;
